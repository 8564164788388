import styled from 'styled-components';

import { StyledNavLink } from '@components/Buttons/Button/styled';

const Wrap = styled.div`
  text-align: center;

  ${StyledNavLink} {
    margin-top: ${(props) => props.theme.spaces.s20};
  }
`;

export { Wrap };
