import React, { InputHTMLAttributes } from 'react';

import { Field, FieldProps } from 'formik';

import CheckboxField, { Props } from '@components/FormElements/BaseFields/Checkbox';

const FormikCheckboxField = ({ name, ...props }: Props & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { error, touched } = form.getFieldMeta(field.name);
        return <CheckboxField {...field} {...props} name={name} error={touched ? error : undefined} />;
      }}
    </Field>
  );
};

export default FormikCheckboxField;
