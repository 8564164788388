interface ClassNamesConfig {
  [key: string]: boolean | undefined;
}

const useClassNames = (classNames: ClassNamesConfig) => {
  return Object.entries(classNames)
    .reduce((acc, [key, value]) => {
      return value && key.length ? `${acc} ${key}` : acc;
    }, '')
    .trim();
};

export default useClassNames;
