import { TokenRefreshLink } from 'apollo-link-oauth-token-refresh';

import { getExpirationDate, getRefresh, setExpirationDate, setRefreshToken, setToken } from '@utils/localStorage';
import { calculateExpirationTime, isExpired } from '@utils/date';
import getRefreshToken from '@services/OAuthServices/getRefreshToken';
import loginClient from '@services/OAuthServices/loginClient';

const isValidExpiredToken = () => {
  const expires = getExpirationDate();
  return expires === null || !isExpired(expires);
};
const RefreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined: isValidExpiredToken,
  fetchAccessToken: () => {
    const refreshToken = getRefresh() || '';
    return getRefreshToken({ password: refreshToken });
  },
  handleResponse:
    () =>
    (response: {
      error?: any;
      token_type?: string;
      access_token?: string;
      expires_in: string;
      refresh_token?: string;
    }) => {
      if (response?.error) {
        return loginClient().then((resp: { error?: any; token_type?: any; access_token?: any; expires_in?: any }) => {
          if (resp?.error) {
            return false;
          }
          const { token_type: type, access_token: token } = resp;
          if (token) {
            const tokenRefreshed = `${type} ${token}`;
            setToken(tokenRefreshed);
            setRefreshToken('');
            return true;
          }
          setToken('');
          setRefreshToken('');
          return false;
        });
      }
      const { token_type: type, access_token: token, expires_in: expires, refresh_token: refresh } = response;
      if (token) {
        const tokenRefreshed = `${type} ${token}`;
        const expirationDate = calculateExpirationTime(expires);

        setToken(tokenRefreshed);
        setExpirationDate(`${expirationDate}`);
        setRefreshToken(`${refresh}`);
        return true;
      }
      setToken('');
      setExpirationDate('');
      setRefreshToken('');
      return false;
    },
  handleError: () => {
    setToken('');
    setExpirationDate('');
    setRefreshToken('');
  },
});

export default RefreshLink;
