import { gql } from '@apollo/client';

const SIGN_UP = gql`
  mutation Signup($user: CreateUserInput!, $options: SignUpOptionsInput) {
    signup(user: $user, options: $options) {
      id
      username
      name
      lastName
      conditions
      dataPolicy
      communicationsPolicy
      active
      activatedAt
      createdAt
      updatedAt
      imageUrl
      role
      legacyContext {
        monavisId
        sazId
      }
    }
  }
`;

export default SIGN_UP;
