import styled from 'styled-components';

const FieldError = styled.p`
  font-size: ${(props) => props.theme.fonts.body3.fontSize};
  line-height: ${(props) => props.theme.fonts.body3.lineHeight};
  margin-top: ${(props) => props.theme.spaces.s4};
  color: ${(props) => props.theme.colors.danger};
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: ${(props) => props.theme.fonts.subtitle2.fontWeight};
  font-size: ${(props) => props.theme.fonts.subtitle2.fontSize};
  line-height: ${(props) => props.theme.fonts.subtitle2.lineHeight};
  color: ${(props) => props.theme.colors.gray2};
  margin-bottom: ${(props) => props.theme.spaces.s4};
`;

const FieldsGroup = styled.div`
  display: grid;
  justify-content: stretch;
  grid-row-gap: ${(props) => props.theme.spaces.s8};
`;

export { FieldsGroup, StyledLabel, FieldError };
