import React, { ChangeEvent, InputHTMLAttributes, useEffect, useState } from 'react';

import { Radio, RadioInput, RadioWrap, RadioLabel } from './styled';

import useRandomElementId from '@hooks/useRandomElementId';
import useIsFirstRender from '@hooks/useIsFirstRender';
import useClassNames from '@hooks/useClassNames';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelSide?: 'left' | 'right';
  error?: string;
  isDisabled?: boolean;
}

const RadioField = ({
  label,
  isDisabled,
  error,
  checked,
  onChange,
  defaultChecked,
  className,
  labelSide,
  ...props
}: Props) => {
  const id = useRandomElementId('radio');
  const isFirstRender = useIsFirstRender();
  const [isChecked, setIsChecked] = useState(checked === undefined ? defaultChecked : checked);
  const isError = Boolean(error);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);

    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (!isFirstRender) {
      setIsChecked(checked);
    }
  }, [checked]);

  const classNames = useClassNames({
    'radio-field': true,
    'radio-field_disabled': isDisabled,
    'radio-field_error': isError,
    [className || '']: Boolean(className),
  });

  return (
    <RadioWrap isError={isError} isDisabled={isDisabled} className={classNames}>
      <RadioInput
        id={id}
        type="radio"
        className="radio-field__input"
        disabled={isDisabled}
        checked={isChecked}
        onChange={handleChange}
        {...props}
      />
      <Radio isChecked={isChecked} isDisabled={isDisabled} isError={isError} className="radio-field__radio" />
      {!!label && (
        <RadioLabel htmlFor={id} className="radio-field__label" isError={isError} labelSide={labelSide}>
          {label}
        </RadioLabel>
      )}
    </RadioWrap>
  );
};

export default RadioField;
