import { getOAuthUrl, getClientSecret } from '@config/getEnviroment';

const urlOAuth = getOAuthUrl();
const clientSecret = getClientSecret();

const params = {
  grant_type: process.env.REACT_APP_CREDENTIALS_GRANT_TYPE,
  client_id: process.env.REACT_APP_CREDENTIALS_CLIENT_ID,
  client_secret: clientSecret,
  scope: process.env.REACT_APP_SCOPE,
};

const loginClient = async () =>
  fetch(urlOAuth as any, {
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(params as any),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });

export default loginClient;
