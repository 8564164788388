import moment from 'moment';
import 'moment/locale/es-mx';

import { getCountry, getLanguage } from '@utils/config';

const calculateExpirationTime = (validSeconds: string): number => Date.now() + parseInt(validSeconds, 10) * 1000 - 10;
const isExpired = (limit: string): boolean => Date.now() >= parseInt(limit, 10);

const getCodeLanguageCountry = (): string => {
  return `${getLanguage().toLowerCase()}//${getCountry().toLowerCase()}`;
};

const parseToLongDate = (inputDate: string, separator: string = ' ') => {
  const formatWithSeparator = `D${separator}MMMM${separator}YYYY`;
  const date = moment(inputDate);
  date.locale(getCodeLanguageCountry());
  return date.format(formatWithSeparator).replace(/\b\w/g, (l) => l.toUpperCase());
};

export { calculateExpirationTime, isExpired, parseToLongDate };
