import React, { ChangeEvent, InputHTMLAttributes, useEffect, useState } from 'react';

import { FormFieldError, Checkbox, CheckboxInput, CheckboxWrap, CheckboxLabel } from './styled';

import useRandomElementId from '@hooks/useRandomElementId';
import useIsFirstRender from '@hooks/useIsFirstRender';
import useClassNames from '@hooks/useClassNames';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelSide?: 'left' | 'right';
  error?: string;
  isDisabled?: boolean;
}

const CheckboxField = ({
  label,
  isDisabled,
  error,
  checked,
  onChange,
  defaultChecked,
  className,
  labelSide,
  ...props
}: Props) => {
  const id = useRandomElementId('checkbox');
  const isFirstRender = useIsFirstRender();
  const [isChecked, setIsChecked] = useState(checked === undefined ? defaultChecked : checked);
  const isError = Boolean(error);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);

    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (!isFirstRender) {
      setIsChecked(checked);
    }
  }, [checked]);

  const classNames = useClassNames({
    'checkbox-field': true,
    'checkbox-field_disabled': isDisabled,
    'checkbox-field_error': isError,
    [className || '']: Boolean(className),
  });

  return (
    <CheckboxWrap isError={isError} isDisabled={isDisabled} className={classNames}>
      <CheckboxInput
        id={id}
        type="checkbox"
        className="checkbox-field__input"
        disabled={isDisabled}
        checked={isChecked}
        onChange={handleChange}
        {...props}
      />
      <Checkbox isChecked={isChecked} isDisabled={isDisabled} className="checkbox-field__checkbox" />
      {!!label && (
        <CheckboxLabel htmlFor={id} className="checkbox-field__label" labelSide={labelSide}>
          {label}
        </CheckboxLabel>
      )}
      {isError && <FormFieldError className="checkbox-field__error">{error}</FormFieldError>}
    </CheckboxWrap>
  );
};

export default CheckboxField;
