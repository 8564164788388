import React, { useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

import { Caption, CloseButton, CloseButtonRow, Container, Fill, PopUp, SubmitFooter, Wrap } from '../styled';

import { H4 } from '@components/Typography';
import { Button } from '@components/Buttons';

export interface ModalProps {
  isOpen: boolean;
  onClose: Function;
  isCanBeClosed?: boolean;
  styledCss?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  caption?: string;
  withCloseButton?: boolean;
  isContentWidth?: boolean;
  cancel?:
    | boolean
    | {
        title?: string;
        onClick?: () => void;
      };
  submit?:
    | boolean
    | {
        title?: string;
        onClick: () => void;
        isDisabled?: boolean;
      };
}

const Modal: React.FunctionComponent<ModalProps> = ({
  caption,
  children,
  isOpen,
  onClose,
  isCanBeClosed = true,
  styledCss,
  cancel,
  submit,
  withCloseButton,
  isContentWidth,
}) => {
  const fillRef = useRef(null);

  const handleClose = useCallback(() => {
    if (isCanBeClosed) {
      onClose();
    }
  }, [isCanBeClosed]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (fillRef.current === event.target) {
        handleClose();
      }
    },
    [onClose, isCanBeClosed]
  );

  const handleEscape = useCallback(
    (event: any) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    },
    [onClose, isCanBeClosed]
  );

  useEffect(() => {
    const release = () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleEscape);
      document.body.style.overflow = 'unset';
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keyup', handleEscape);
      document.body.style.overflow = 'hidden';
    } else {
      release();
    }
    return release;
  }, [isOpen, handleClickOutside, handleEscape]);

  return isOpen
    ? ReactDOM.createPortal(
        <Wrap styledCss={styledCss} className="modal">
          <Fill ref={fillRef} className="modal__fill" />
          <PopUp className="modal__popup" isContentWidth={isContentWidth}>
            {caption && (
              <Caption className="modal__caption-block">
                <H4 className="modal__caption">{caption}</H4>
                {withCloseButton && <CloseButton className="modal__close-btn" onClick={() => onClose()} />}
              </Caption>
            )}
            <Container className="modal__container">
              {withCloseButton && !caption && (
                <CloseButtonRow>
                  <CloseButton className="modal__close-btn" onClick={() => onClose()} />
                </CloseButtonRow>
              )}
              {children}
              {(cancel || submit) && (
                <SubmitFooter className="modal__footer">
                  {cancel && (
                    <Button
                      type="button"
                      $type="ghost"
                      onClick={() => {
                        if (typeof cancel !== 'boolean' && cancel.onClick) {
                          cancel.onClick();
                        }
                        handleClose();
                      }}
                      className="modal__cancel-btn"
                    >
                      {(typeof cancel !== 'boolean' && cancel.title) || 'Cancel'}
                    </Button>
                  )}
                  {submit && (
                    <Button
                      type="button"
                      isDisabled={typeof submit !== 'boolean' ? submit.isDisabled : undefined}
                      onClick={async () => {
                        if (typeof submit !== 'boolean' && submit.onClick) {
                          submit.onClick();
                        }
                      }}
                      className="modal__submit-btn"
                      data-test="modal-submit-btn"
                    >
                      {(typeof submit !== 'boolean' && submit.title) || 'Save'}
                    </Button>
                  )}
                </SubmitFooter>
              )}
            </Container>
          </PopUp>
        </Wrap>,
        document.body
      )
    : null;
};

export default Modal;
