import React from 'react';
import { useSelector } from 'react-redux';

import { Wrap } from './styled';
import Notification from './Notification';

import { notificationsSelector } from '@store/notifications/selectors';

const Notifications = () => {
  const notifications = useSelector(notificationsSelector);

  if (notifications.length === 0) return null;
  return (
    <Wrap>
      {notifications.map((n) => (
        <Notification key={n.id} notification={n} />
      ))}
    </Wrap>
  );
};

export default Notifications;
