import { Comment } from '@/types/comments';

export enum ActionTypes {
  FETCH_COMMENTS_START = 'history/FETCH_COMMENTS_START',
  FETCH_COMMENTS_SUCCESS = 'history/FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_FAIL = 'history/FETCH_COMMENTS_FAIL',
}

export interface State {
  isLoading: boolean;
  error: string;
  items: Comment[];
}

export interface FetchCommentsStartAction {
  type: ActionTypes.FETCH_COMMENTS_START;
}
export interface FetchCommentsSuccessAction {
  type: ActionTypes.FETCH_COMMENTS_SUCCESS;
  payload: Comment[];
}
export interface FetchCommentsFailAction {
  type: ActionTypes.FETCH_COMMENTS_FAIL;
  payload: string;
}

export type ActionType = FetchCommentsStartAction | FetchCommentsSuccessAction | FetchCommentsFailAction;
