import { ActionTypes, AuthorizeUserAction, SetUserId, OAuthData, LogoutUserAction } from './types';

const setOAuthWithUser = (payload: OAuthData): AuthorizeUserAction => ({
  type: ActionTypes.AUTHORIZE_USER,
  payload: {
    token: payload.token,
    isLogin: payload?.isLogin,
    isAuthenticated: true,
    refresh: payload?.refresh,
    expires: payload?.expires,
  },
});

const setOAuthWithClient = (payload: OAuthData): AuthorizeUserAction => ({
  type: ActionTypes.AUTHORIZE_USER,
  payload: {
    token: payload.token,
    isLogin: false,
    isAuthenticated: true,
    refresh: undefined,
    expires: payload?.expires,
  },
});

const setUserId = (payload: string): SetUserId => ({
  type: ActionTypes.SET_USERID,
  payload,
});

const logOut = (): LogoutUserAction => ({
  type: ActionTypes.LOGOUT_USER,
});

export { setOAuthWithUser, setOAuthWithClient, setUserId, logOut };
