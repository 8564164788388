import statesAndCities from '@helpers/statesAndCities';

const genderOptions = [
  { label: 'option.gender.choose', value: '' },
  { label: 'option.gender.MALE', value: 'MALE' },
  { label: 'option.gender.FEMALE', value: 'FEMALE' },
  { label: 'option.gender.NOT_ANSWERED', value: 'NOT_ANSWERED' },
];

const preferenceOptions = [
  { id: 'alcohol', title: 'Alcohol' },
  { id: 'bio', title: 'Bio' },
  { id: 'light', title: 'Light' },
  { id: 'noGluten', title: 'Without gluten' },
];

const numberOfHomeComponentsOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: 'More than 5', value: '5+' },
];

const partnerOptions = [
  { label: '', value: 'Choose one' },
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: '-', value: 'NOT_ANSWERED' },
];
const educationOptions = [
  { value: '0', label: 'Level 0: Pre-primary education' },
  { value: '1', label: 'Level 1: Primary education or first stage of basic education' },
  { value: '2', label: 'Level 2: Lower secondary education or second stage of basic education' },
  { value: '3', label: 'Level 3: Upper secondary education' },
  { value: '4', label: 'Level 4: Post-secondary non-tertiary education' },
  { value: '5', label: 'Level 5: Short-cycle tertiary education' },
  { value: '6', label: "Level 6: Bachelor's or equivalent" },
  { value: '7', label: "Level 7: Master's or equivalent" },
  { value: '8', label: 'Level 8: Doctoral or equivalent' },
];

const policiesOptions = [
  {
    code: 'platformCommunications',
    name: 'Platform Communications',
  },
  {
    code: 'retailerCommunications',
    name: 'Retailer Communications',
  },
  {
    code: 'dataLinkWithSAZ',
    name: 'Link profile with ShopAdvizor',
  },
  {
    code: 'thirdPartyCommunications',
    name: 'Third Party Communications',
  },
];

const telephonePoliciesOptions = [
  {
    code: 'telephoneSazCommunications',
    name: 'SAZ telephone communications',
  },
  {
    code: 'telephoneOthersCommunications',
    name: 'Walmart Aurrera telehone communications',
  },
];

const buildStateOptions = () => {
  const stateRaw: any = Object.keys(statesAndCities());
  const response: any = [];

  stateRaw.forEach((item: any) => {
    response.push({
      label: item,
      value: item,
    });
  });

  return response;
};
const buildCityOptions = (state: any) => {
  const stateRaw: any = statesAndCities();
  const response: any = [];
  if (state !== undefined) {
    if (stateRaw[state]) {
      stateRaw[state].forEach((item: any) => {
        response.push({
          label: item,
          value: item,
        });
      });
    }
    return response;
  }

  return response;
};
const translateOptions = (options: any, t: any) => {
  return options.map((option: any) => {
    return {
      label: t(option.label),
      value: option.value,
    };
  });
};

export {
  genderOptions,
  numberOfHomeComponentsOptions,
  partnerOptions,
  policiesOptions,
  buildCityOptions,
  buildStateOptions,
  educationOptions,
  preferenceOptions,
  translateOptions,
  telephonePoliciesOptions,
};
