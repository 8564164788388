import React from 'react';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';

import InputField, { Props } from '@components/FormElements/BaseFields/Input';

const FormikInputField = ({ name, fieldProps, ...props }: Props) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { error, touched } = form.getFieldMeta(field.name);
        return (
          <InputField
            {...field}
            {...props}
            name={name}
            fieldProps={{ error: touched ? error : undefined, ...fieldProps }}
          />
        );
      }}
    </Field>
  );
};

export default FormikInputField;
