import { ChangeEvent, InputHTMLAttributes, FocusEvent, useState, forwardRef, ForwardRefRenderFunction } from 'react';

import useRandomElementId from '@hooks/useRandomElementId';
import Field, { FieldProps } from '@components/FormElements/BaseFields/Field';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  fieldProps?: FieldProps;
}

const InputField: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { onFocus, onBlur, onChange, value, fieldProps, ...rest },
  ref
) => {
  const id = useRandomElementId('input');
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(Boolean(value));

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHasValue(Boolean(e.target.value));
    if (onChange) onChange(e);
  };

  return (
    <Field
      hasValue={hasValue}
      isFocused={isFocused}
      component={
        <input
          id={id}
          ref={ref}
          className="field-input-element form-field__input"
          type="text"
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          {...rest}
        />
      }
      {...fieldProps}
    />
  );
};

export default forwardRef(InputField);
