import styled from 'styled-components';

import { Subtitle } from '@components/Typography';

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

const Border = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray6};
  width: 100%;
`;

const Content = styled(Subtitle)`
  padding: 0 ${(props) => props.theme.spaces.s8};
`;

export { Wrap, Border, Content };
