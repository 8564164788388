import { createGlobalStyle } from 'styled-components';

import reset from './reset';

import '@assets/fonts/goldpay/fonts.css';

const GlobalStyle = createGlobalStyle`
  ${reset}

  // additional reset
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
  }
  a {
    color: inherit;
    cursor: pointer;
  }
  button {
    cursor: pointer;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
  }
  input {
    font: inherit;
  }
  // additional reset
  
  // fix for user agent stylesheet
  button, textarea {
    font: inherit;
  }
  // fix for user agent stylesheet

  html, body {
    height: 100%;
  }
  html {
    ${({ theme }) => theme.fonts.primary.fontSize};
  }
  body {
    font-family: ${({ theme }) => theme.fonts.primary.fontFamily};
    color: ${({ theme }) => theme.colors.text.default};
    background-color: ${({ theme }) => theme.colors.background};
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  a.link_external {
    color: ${(props) => props.theme.colors.primary_lighter};
  }
  .nowrap {
    white-space: nowrap;
  }
  span .react-loading-skeleton {
    border-radius: ${(props) => props.theme.borderRadius.r8};
    line-height: inherit;
  }
`;

export default GlobalStyle;
