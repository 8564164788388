import { User } from '@/types/user';
import { Product } from '@/types/product';

interface Options {
  trailing?: boolean;
  leading?: boolean;
  maxWait?: number;
}

function debounce(func: (...args: any[]) => void, wait: number, options: Options = {}) {
  const { trailing = true, leading = false, maxWait } = options;
  let timeoutId: NodeJS.Timeout;
  let maxTimeoutId: any | null = null;

  return (...args: any[]): void => {
    const callImmediate = leading && !timeoutId;
    const callAnyway = maxWait && !maxTimeoutId;

    if (callImmediate) func.apply(this, args);

    if (callAnyway) {
      maxTimeoutId = setTimeout(() => {
        if (trailing) func.apply(this, args);
        maxTimeoutId = null;
      }, maxWait);
    }

    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      if (trailing) func.apply(this, args);
      if (maxTimeoutId) {
        clearTimeout(maxTimeoutId);
        maxTimeoutId = null;
      }
    }, wait);
  };
}

const getRandomHash = (prefix = 'element') =>
  `${prefix ? `${prefix}-` : ''}${Math.random().toString(36).substring(2, 15)}`;

const formatMoney = (val: number) => new Intl.NumberFormat().format(val);

function generateArray<T = string>(length: number, fill?: (id: string) => T) {
  return Object.keys(new Array(length).fill('')).map((id) => (fill ? fill(id) : (id as unknown as T)));
}

const parseProducts = (data: any): Product[] => {
  const { products } = data || [];
  const { results } = products || [];

  if (results && results.length > 0) {
    return results.map((product: any) => {
      const { id, name, brand, aggregateRating, composition, ean, images } = product;
      const photo = images.find((image: any) => image.type === 'thumbnail');
      const brandName = brand?.name || '';
      const { average, ratingCount } = aggregateRating || 0;
      const { nutritionInformation } = composition || {};
      const { nutriscore } = nutritionInformation || '-';
      return {
        id,
        ean,
        img: photo?.url,
        title: name,
        subTitle: brandName,
        mark: average,
        markAmount: ratingCount,
        isDiscount: false,
        isFavorite: false,
        nutriscore,
      };
    });
  }
  return [];
};

const mergePhoto = (data: any, photo: string): User => {
  const {
    email,
    firstName,
    lastName,
    state,
    city,
    zipCode,
    line1,
    line2,
    gender,
    birthDate,
    partnerBirthDate,
    partnerFirstName,
    partnerGender,
    educationLevelCode,
    partner,
    hasPet,
    hasPartner,
    children,
    pets,
    policies,
    numberOfHomeComponents,
    preferences,
    allergens,
    id,
  } = data;
  return {
    photo,
    email,
    firstName,
    lastName,
    state,
    city,
    zipCode,
    line1,
    line2,
    gender,
    birthDate,
    partnerBirthDate,
    partnerFirstName,
    partnerGender,
    educationLevelCode,
    partner,
    hasPet,
    hasPartner,
    children,
    pets,
    policies,
    numberOfHomeComponents,
    preferences,
    allergens,
    id,
  };
};

const parseProductByEanOnDetailsType = (queryResults: any, counters: any) => {
  const { name, brand, aggregateRating, description, images, composition, accolades } = queryResults;
  const photo = images.find((image: any) => image.type === 'thumbnail');
  const accoladeActive = accolades.find((accolade: any) => accolade.active === true);
  const { average, ratingCount } = aggregateRating;
  const { text: compositionText, nutritionInformation, measureInformation } = composition;
  const nutriscore = nutritionInformation?.nutriscore;
  const contents = nutritionInformation?.contents;
  const allergens = nutritionInformation?.allergens;
  const measureLabel = measureInformation?.label;
  const { name: brandName } = brand;

  return {
    name,
    brandName,
    photo: photo?.url,
    average,
    ratingCount,
    description,
    compositionText,
    nutriscore,
    contents,
    allergens,
    accoladeActive,
    counters,
    measureLabel,
  };
};

const isAccoladeShowable = (code: string) => {
  return code === 'sazInnovation' || code === 'sazInnovationChoice';
};

const populateRatings = (data: any) => {
  const { counters } = data;
  const { counters: countersData } = counters;
  const ratingsPopulate = [];
  const maxStarRating = 5;
  const minStarRating = 1;
  const increment = 1;
  for (let i = minStarRating; i <= maxStarRating; i += increment) {
    const counter = countersData.find((item: any) => parseInt(item.name, 10) === i);
    ratingsPopulate[i] = counter || {
      name: i,
      count: 0,
      ratio: 0,
    };
  }

  return ratingsPopulate;
};

const FIRST_CHAR = 0;
const FIRST_ELEMENT = 0;

export {
  getRandomHash,
  formatMoney,
  isAccoladeShowable,
  debounce,
  generateArray,
  parseProducts,
  mergePhoto,
  parseProductByEanOnDetailsType,
  populateRatings,
  FIRST_CHAR,
  FIRST_ELEMENT,
};
