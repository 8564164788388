import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionButton, TopHead, IconWrap, NotificationWrap, Title } from './styled';
import Icon from './Icon';

import { Notification as NotificationInterface } from '@store/notifications/types';
import { removeNotificationActionCreator } from '@store/notifications/actions';

interface Props {
  notification: NotificationInterface;
}

const delay = 3000;
const animationDuration = 500;

const Notification: React.FunctionComponent<Props> = ({ notification }) => {
  const visibilityTimeout = useRef<NodeJS.Timeout>();
  const removalTimeout = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);

  const remove = useCallback((immediate?: boolean) => {
    visibilityTimeout.current = setTimeout(
      () => {
        setIsVisible(false);
      },
      immediate ? 0 : delay - animationDuration
    );
    removalTimeout.current = setTimeout(
      () => {
        dispatch(removeNotificationActionCreator(notification.id));
      },
      immediate ? animationDuration : delay
    );
  }, []);

  useEffect(() => {
    remove();
  }, []);

  return (
    <NotificationWrap isVisible={isVisible} type={notification.type} animationDuration={animationDuration}>
      <TopHead>
        <div>
          <Title>
            <IconWrap>
              <Icon type={notification.type} />
            </IconWrap>
            {notification.title}
          </Title>
        </div>
        {!!notification.action && (
          <ActionButton
            onClick={async () => {
              clearTimeout(visibilityTimeout.current!);
              clearTimeout(removalTimeout.current!);
              const isRemove = await notification.action?.onClick!();
              if (isRemove) remove(true);
            }}
          >
            {notification.action.title}
          </ActionButton>
        )}
      </TopHead>
    </NotificationWrap>
  );
};

export default Notification;
