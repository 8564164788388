import { ActionType, ActionTypes, OAuthData } from './types';

const initialState = {
  isAuthenticated: false,
  token: undefined,
  isLogin: false,
  expires: Date.now(),
  refresh: undefined,
  userId: undefined,
};

const authReducer = (state: OAuthData = initialState, action: ActionType): OAuthData => {
  switch (action.type) {
    case ActionTypes.AUTHORIZE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.AUTHORIZE_CLIENT:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.SET_USERID:
      return {
        ...state,
        userId: action.payload,
      };
    case ActionTypes.LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
