import { useEffect, useState } from 'react';

const useWindowHeight = () => {
  const [mobileWindowHeight, setMobileWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const listener = () => setMobileWindowHeight(window.innerHeight);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return mobileWindowHeight;
};

export default useWindowHeight;
