import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Wrap } from './styled';
import SignUpForm from './Form';

import CREATE_PROFILE from '@graphql/mutations/createProfile';
import SIGN_UP from '@graphql/mutations/signUp';
import SEND_ACTIVATION_EMAIL from '@graphql/mutations/sendActivationEmail';
import Success from '@components/Success';
import { addNotificationActionCreator } from '@store/notifications/actions';
import { NotificationType } from '@store/notifications/types';
import { email } from '@utils/validation';
import { getBaseUrl, MINIMAL_AGE } from '@utils/config';
import history from '@utils/history';
import { buildSignUpParameters, getSignUpFormValues } from '@utils/user';
import { buildProfileParameters } from '@utils/profile';

const SignUp = () => {
  const { t } = useTranslation();
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUp] = useMutation(SIGN_UP);
  const [createProfile] = useMutation(CREATE_PROFILE);
  const [sendActivationEmail] = useMutation(SEND_ACTIVATION_EMAIL);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: email(true, t('validation.error.invalidEmail')),
    firstName: Yup.string()
      .required(t('validation.error.required'))
      .matches(/^[^0-9]+$/, t('validation.error.numbersNotAllowed')),
    lastName: Yup.string()
      .required(t('validation.error.required'))
      .matches(/^[^0-9]+$/, t('validation.error.numbersNotAllowed')),
    gender: Yup.string().required(t('validation.error.required')),
    birthDate: Yup.string()
      .required(t('validation.error.required'))
      .test('birthDate', t('validation.error.invalidBirthDate'), (value) => {
        return moment().diff(moment(value, 'DD/MM/YYYY'), 'years') >= MINIMAL_AGE;
      }),
    password: Yup.mixed().required(t('validation.error.required')),
    state: Yup.mixed().required(t('validation.error.required')),
    city: Yup.mixed().required(t('validation.error.required')),
    zipCode: Yup.mixed().required(t('validation.error.required')),
    line1: Yup.mixed().required(t('validation.error.required')),
  });

  return (
    <Wrap>
      {isRegistered ? (
        <Success
          onClose={() => {}}
          title={t('pages.successSignUp.caption.title')}
          text={t('pages.successSignUp.texts.success')}
        />
      ) : (
        <Formik
          initialValues={{
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            gender: '',
            birthDate: '',
            state: '',
            city: '',
            zipCode: '',
            line1: '',
            line2: '',
          }}
          validationSchema={validationSchema}
          /* eslint-disable */
          onSubmit={(values: FormikValues) => {
            setIsLoading(true);
            const variablesVar = buildSignUpParameters(getSignUpFormValues(values));
            signUp({variables: variablesVar})
              .then((res) => {
                const { data } = res;
                const { signup } = data;
                const { id } = signup;
                /* eslint-disable */
                createProfile({variables: buildProfileParameters(values, id)})
                  .then(() => {
                    const activationParams = {
                      input: {
                        username: values?.email,
                        landingPageUrl: `${getBaseUrl()}/confirmEmail/:token:`,
                      },
                    }
                    sendActivationEmail({variables: {...activationParams}})
                      .then(() => {
                        return setIsRegistered(true);
                      })
                      .catch(() => {
                        return setIsRegistered(true);
                      })
                    return setIsRegistered(true);
                  })
                  .catch(() => {
                    return setIsRegistered(true);
                  });
                return setIsRegistered(true);
              })
              .catch(() => {
                setIsLoading(false);

                return dispatch(
                  addNotificationActionCreator({
                    type: NotificationType.Error,
                    title: t('forms.signUp.error.network'),
                  })
                );
              });
          }}
        >
          <SignUpForm handleSignInClick={() => history.push('/sign-in')} isLoading={isLoading} />
        </Formik>
      )}
    </Wrap>
  );
};

export default SignUp;
