import styled, { css } from 'styled-components';

import BaseField, { Props } from '@components/FormElements/BaseFields/Field';

const Counter = styled.p`
  font-size: ${(props) => props.theme.fonts.body2.fontSize};
  line-height: ${(props) => props.theme.fonts.body2.lineHeight};
  color: ${(props) => props.theme.colors.gray3};
`;

const Field = styled(BaseField)<Props & { hasLimit?: boolean }>`
  position: relative;

  ${(props) =>
    props.hasLimit &&
    css`
      .textarea-wrap textarea.form-field__textarea {
        padding-bottom: 0;
        margin-bottom: ${props.theme.spaces.s32};
      }
    `};

  ${Counter} {
    position: absolute;
    right: ${(props) => props.theme.spaces.s12};
    bottom: ${(props) => props.theme.spaces.s8};
  }
`;

export { Field, Counter };
