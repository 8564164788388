import { User, UserSummary } from '@/types/user';

export enum ActionTypes {
  FETCH_USER_START = 'user/FETCH_USER_START',
  FETCH_USER_SUCCESS = 'user/FETCH_USER_SUCCESS',
  FETCH_USER_FAIL = 'user/FETCH_USER_FAIL',
  CLEAN_USER = 'user/CLEAN_USER',
  FETCH_USER_SUMMARY = 'user/FETCH_USER_SUMMARY',
  FETCH_USER_CREDITS = 'user/FETCH_USER_CREDITS',
}

export interface State {
  isLoading: boolean;
  error: string;
  credits?: number;
  summary?: UserSummary;
  data?: User;
}

export interface FetchUserStartAction {
  type: ActionTypes.FETCH_USER_START;
}
export interface FetchUserSuccessAction {
  type: ActionTypes.FETCH_USER_SUCCESS;
  payload: User;
}
export interface FetchUserFailAction {
  type: ActionTypes.FETCH_USER_FAIL;
  payload: string;
}

export interface cleanUserAction {
  type: ActionTypes.CLEAN_USER;
}

export interface FetchUserSummaryAction {
  type: ActionTypes.FETCH_USER_SUMMARY;
  payload: UserSummary;
}

export interface FetchUserCreditsAction {
  type: ActionTypes.FETCH_USER_CREDITS;
  payload: number;
}

export type ActionType =
  | FetchUserStartAction
  | FetchUserSuccessAction
  | FetchUserFailAction
  | cleanUserAction
  | FetchUserSummaryAction
  | FetchUserCreditsAction;
