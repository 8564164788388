import { RootState } from '@store';

const isAuthenticated = (state: RootState) => state.auth.isAuthenticated;
const token = (state: RootState) => state.auth.token;
const isLogin = (state: RootState) => state.auth.isLogin;
const userId = (state: RootState) => state.auth.userId;
const refresh = (state: RootState) => state.auth.refresh;
const expires = (state: RootState) => state.auth.expires;

export { isAuthenticated, token, isLogin, userId, refresh, expires };
