import { ReactElement } from 'react';

export enum ActionTypes {
  ADD_NOTIFICATION = 'notifications/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'notifications/REMOVE_NOTIFICATION',
}

export enum NotificationType {
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
  Info = 'Info',
}

export interface Notification {
  id: number;
  type?: NotificationType;
  title?: string | ReactElement;
  action?: {
    title: string;
    onClick: () => Promise<boolean>;
  };
}

export type NotificationPayload = Omit<Notification, 'id'>;

export interface State {
  items: Notification[];
}

export interface AddNotificationAction {
  type: ActionTypes.ADD_NOTIFICATION;
  payload: Notification;
}
export interface RemoveNotificationAction {
  type: ActionTypes.REMOVE_NOTIFICATION;
  payload: number;
}

export type ActionType = AddNotificationAction | RemoveNotificationAction;
