import { FormikValues } from 'formik';

import { policiesOptions } from '@utils/options';
import { Children, DataFromMe, Profile, User } from '@/types/user';

const buildNotAnswered = (key: string, value: string, target: any) => {
  if (value === 'NOT_ANSWERED') {
    target.push(key);
  }
};
const buildPolicies = (policyValues: string[]) => {
  const output: any[] = [];

  policyValues?.forEach((item: string) => {
    const policy = policiesOptions.find((option: { code: string; name: string }) => option.code === item);
    if (policy) {
      output.push({
        ...policy,
      });
    }
  });

  return output;
};
const buildEducation = (code: string) => {
  return {
    code,
    name: '',
    description: '',
  };
};
const buildPets = (petsValues: string[]) => {
  const output: any[] = [];

  petsValues?.forEach((item: string) => {
    const pet = { type: item };
    output.push({
      ...pet,
    });
  });

  return output;
};
const buildChilds = (childValues: Children[]) => {
  const output: any[] = [];

  childValues?.forEach((item: Children) => {
    const child = { firstName: item.firstName, birthDate: item.birthDate };
    output.push({
      ...child,
    });
  });

  return output;
};
const buildPreference = (preferenceValues: string[]) => {
  const alcohol = preferenceValues?.indexOf('alcohol') !== -1;
  const bio = preferenceValues?.indexOf('bio') !== -1;
  const light = preferenceValues?.indexOf('light') !== -1;
  const noGluten = preferenceValues?.indexOf('noGluten') !== -1;

  return {
    alcohol,
    bio,
    light,
    noGluten,
  };
};
const buildPreferencesFromQuery = (values: { alcohol: boolean; bio: boolean; light: boolean; noGluten: boolean }) => {
  const output = [];
  if (values?.alcohol === true) {
    output.push('alcohol');
  }
  if (values?.bio === true) {
    output.push('bio');
  }
  if (values?.light === true) {
    output.push('light');
  }
  if (values?.noGluten === true) {
    output.push('noGluten');
  }

  return output;
};
const buildDataUserFromMe = (dataFromMe: DataFromMe): User => {
  const { data } = dataFromMe;
  const { me } = data;
  const { username, name, lastName, imageUrl, profile, id: idUser, telephone, telephoneVerifiedAt } = me;
  const {
    gender,
    birthDate,
    address,
    children,
    pets,
    educationLevel,
    partner,
    numberOfHomeComponents,
    preference,
    policies,
    id,
  } = profile;
  const { line1, line2, city, postalCode, state } = address || {};
  const firstName = partner?.firstName;
  const birthDatePartner = partner?.birthDate;
  const genderPartner = partner?.gender;
  const hasPartner = birthDatePartner ? 'YES' : 'NO';
  const hasPet = pets ? 'YES' : 'NO';
  const buildPreferences = (values: any) => {
    const output = [];
    if (values?.alcohol === true) {
      output.push('alcohol');
    }
    if (values?.bio === true) {
      output.push('bio');
    }
    if (values?.light === true) {
      output.push('light');
    }
    if (values?.noGluten === true) {
      output.push('noGluten');
    }

    return output;
  };

  return {
    email: username,
    firstName: name,
    lastName,
    photo: imageUrl,
    state,
    city,
    zipCode: postalCode,
    line1,
    line2,
    gender,
    birthDate,
    partnerBirthDate: birthDatePartner,
    partnerFirstName: firstName,
    partnerGender: genderPartner,
    educationLevelCode: educationLevel?.code,
    partner: {
      firstName,
      birthDate: birthDatePartner,
      gender: genderPartner,
    },
    hasPet,
    hasPartner,
    children,
    pets: pets?.map((object: any) => object.type),
    policies: policies?.map((object: any) => object.code),
    numberOfHomeComponents,
    preferences: buildPreferences(preference),
    allergens: [],
    id,
    userId: idUser,
    telephone,
    isTelephoneConfirmed: telephoneVerifiedAt !== null,
  };
};
const buildDataUserFromQuery = (props: any) => {
  const { data } = props;
  const { me, profile } = data;
  const { updateProfile } = profile;
  const { username, name, lastName, imageUrl } = me;
  const {
    gender,
    birthDate,
    address,
    children,
    pets,
    educationLevel,
    partner,
    numberOfHomeComponents,
    preference,
    policies,
    id,
  } = updateProfile;
  const { line1, line2, city, postalCode, state } = address || {};
  const firstName = partner?.firstName;
  const birthDatePartner = partner?.birthDate;
  const genderPartner = partner?.gender;
  const hasPartner = birthDatePartner ? 'YES' : 'NO';
  const hasPet = pets ? 'YES' : 'NO';
  return {
    email: username,
    firstName: name,
    lastName,
    photo: imageUrl,
    state,
    city,
    zipCode: postalCode,
    line1,
    line2,
    gender,
    birthDate,
    partnerBirthDate: birthDatePartner,
    partnerFirstName: firstName,
    partnerGender: genderPartner,
    educationLevelCode: educationLevel?.code,
    partner: {
      firstName,
      birthDate: birthDatePartner,
      gender: genderPartner,
    },
    hasPet,
    hasPartner,
    children,
    pets: pets?.map((object: any) => object.type),
    policies: policies?.map((object: any) => object.code),
    numberOfHomeComponents,
    preferences: buildPreferencesFromQuery(preference),
    allergens: [],
    id,
  };
};
const buildFormValues = (params: any) => {
  const { user } = params;
  const {
    state,
    line1,
    line2,
    zipCode,
    birthDate,
    country,
    city,
    gender,
    hasPartner,
    hasPet,
    pets,
    policies,
    preferences,
    children,
    partnerBirthDate,
    partnerFirstName,
    partnerGender,
    educationLevelCode,
    partner,
    numberOfHomeComponents,
    allergens,
    id,
  } = user;
  const output: Profile = {
    state,
    line1,
    line2,
    zipCode,
    birthDate,
    country,
    city,
    gender,
    hasPartner,
    hasPet,
    pets,
    policies,
    preferences,
    children,
    partnerBirthDate,
    partnerFirstName,
    partnerGender,
    educationLevelCode,
    partner,
    numberOfHomeComponents,
    allergens,
    id,
  };
  return output;
};
const buildRequestParameters = (params: any) => {
  const { values, isNewProfile, id, user } = params;
  const {
    gender,
    birthDate,
    city,
    line1,
    line2,
    zipCode,
    state,
    numberOfHomeComponents,
    hasPartner,
    hasPet,
    pets,
    policies,
    preferences,
    partnerBirthDate,
    partnerFirstName,
    partnerGender,
    educationLevelCode,
    children,
  } = values;
  const notAnswered: string[] = [];

  buildNotAnswered('partner', hasPartner, notAnswered);
  buildNotAnswered('gender', gender, notAnswered);
  buildNotAnswered('pet', hasPet, notAnswered);
  const buildValuePolicy = buildPolicies(policies);
  const buildValuePets = buildPets(pets);
  const buildValueChilds = buildChilds(children);
  const buildValuePreference = buildPreference(preferences);
  const buildValueEducation = buildEducation(educationLevelCode);
  const partnerObject =
    hasPartner === 'YES'
      ? {
          firstName: partnerFirstName,
          birthDate: partnerBirthDate !== '' ? partnerBirthDate : null,
          gender: partnerGender !== '' ? partnerGender : null,
        }
      : null;
  const checkedNumberOfHomeComponents = hasPartner !== 'YES' ? '1' : numberOfHomeComponents;
  const input = {
    userId: id,
    gender,
    birthDate,
    numberOfHomeComponents: checkedNumberOfHomeComponents,
    partner: partnerObject,
    pets: buildValuePets,
    notAnswered,
    policies: buildValuePolicy,
    educationLevel: buildValueEducation,
    address: {
      city,
      line1,
      line2,
      state,
      postalCode: zipCode,
    },
    children: buildValueChilds,
    preference: buildValuePreference,
    phoneNumber: '',
  };
  return isNewProfile
    ? {
        input,
      }
    : {
        id: user.id,
        input,
      };
};
const buildProfileParameters = (props: FormikValues, id: string) => {
  const notAnswered: string[] = [];
  const parseBirthDate = (date: string) => {
    const splitDate = date.split('/');

    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  };

  const { gender, birthDate, phoneNumber, state, city, zipCode, line1, line2 } = props;
  buildNotAnswered('gender', gender, notAnswered);
  const input = {
    userId: id,
    gender,
    birthDate: parseBirthDate(birthDate),
    address: {
      line1,
      line2,
      postalCode: zipCode,
      city,
      state,
    },
    phoneNumber,
    notAnswered,
  };
  return { input };
};
const buildProfilePoliciesParameters = (
  traslate: (key: string) => string,
  policies?: string[],
  userId?: string,
  profileId?: string,
  addPolicies?: string[]
) => {
  const builtPoliciesOld = policies?.length ? policies : [];
  const builtPoliciesNew = addPolicies?.length ? { ...builtPoliciesOld, ...addPolicies } : builtPoliciesOld;
  const parsed: { code: string; name: string }[] = [];
  Object.values(builtPoliciesNew).forEach((item) => {
    parsed.push({ code: item, name: traslate(`option.policies.${item}`) });
  });
  const input = {
    userId,
    policies: parsed,
  };
  return { id: profileId, input };
};

export {
  buildFormValues,
  buildRequestParameters,
  buildDataUserFromQuery,
  buildDataUserFromMe,
  buildProfileParameters,
  buildProfilePoliciesParameters,
};
