import { Order } from '@/types/order';

export enum ActionTypes {
  FETCH_ORDERS_BY_USER_START = 'orders/FETCH_ORDERS_BY_USER_START',
  FETCH_ORDERS_BY_USER_SUCCESS = 'orders/FETCH_ORDERS_BY_USER_SUCCESS',
  FETCH_ORDERS_BY_USER_FAIL = 'orders/FETCH_ORDERS_BY_USER_FAIL',
  CLEAR_ORDERS = 'orders/CLEAR_ORDERS',
  CHANGE_PAGE = 'orders/CHANGE_PAGE',
  SAVE_SELECTED_ORDER = 'orders/SAVE_SELECTED_ORDER',
}

export interface State {
  isLoading: boolean;
  error: string;
  items: Order[];
  page: number;
  totalItems: number;
  details: {
    isLoading: boolean;
    error: string;
    data?: Order | null;
  };
  selected?: Order | undefined;
}

export interface FetchOrdersByUserStartAction {
  type: ActionTypes.FETCH_ORDERS_BY_USER_START;
}
export interface FetchOrdersByUserSuccessAction {
  type: ActionTypes.FETCH_ORDERS_BY_USER_SUCCESS;
  payload: Order[];
  totalItems: number;
}
export interface FetchOrdersByUserFailAction {
  type: ActionTypes.FETCH_ORDERS_BY_USER_FAIL;
  payload: string;
}

export interface ClearOrders {
  type: ActionTypes.CLEAR_ORDERS;
}

export interface SaveSelectedOrder {
  type: ActionTypes.SAVE_SELECTED_ORDER;
  payload: Order;
}

export type ActionType =
  | FetchOrdersByUserStartAction
  | FetchOrdersByUserSuccessAction
  | FetchOrdersByUserFailAction
  | ClearOrders
  | SaveSelectedOrder;
