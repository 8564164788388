import { ButtonHTMLAttributes } from 'react';

import { StyledActionButton } from './styled';
import useActionButton, { ActionButtonProps } from './useActionButton';

const ActionButton = (props: ActionButtonProps<ButtonHTMLAttributes<HTMLButtonElement>>) => {
  const { isDisabled, onClick, ...modifiedProps } = useActionButton<ButtonHTMLAttributes<HTMLButtonElement>>(props);

  return (
    <StyledActionButton
      onClick={(e) => {
        if (!isDisabled && onClick) onClick(e);
      }}
      isDisabled={isDisabled}
      {...modifiedProps}
    />
  );
};

export default ActionButton;
