import { FavoriteList } from '@/types/favorites';

export enum ActionTypes {
  FETCH_FAVORITES_START = 'history/FETCH_FAVORITES_START',
  FETCH_FAVORITES_SUCCESS = 'history/FETCH_FAVORITES_SUCCESS',
  FETCH_FAVORITES_FAIL = 'history/FETCH_FAVORITES_FAIL',
}

export interface State {
  isLoading: boolean;
  error: string;
  items: FavoriteList[];
}

export interface FetchFavoritesStartAction {
  type: ActionTypes.FETCH_FAVORITES_START;
}
export interface FetchFavoritesSuccessAction {
  type: ActionTypes.FETCH_FAVORITES_SUCCESS;
  payload: FavoriteList[];
}
export interface FetchFavoritesFailAction {
  type: ActionTypes.FETCH_FAVORITES_FAIL;
  payload: string;
}

export type ActionType = FetchFavoritesStartAction | FetchFavoritesSuccessAction | FetchFavoritesFailAction;
