import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Wrap as BaseWrap } from '../styled';

import { Paragraph } from '@components/Typography';
import { ViewModeQueries } from '@helpers/viewModeHelper';

const Wrap = styled(BaseWrap)`
  flex-direction: column;
  justify-content: center;

  ${ViewModeQueries.MD} {
    flex-direction: row;
    padding: ${(props) => props.theme.spaces.s40} ${(props) => props.theme.spaces.s12};
  }

  ${Paragraph} {
    text-align: center;
    margin: ${(props) => props.theme.spaces.s12} auto;
    max-width: 75%;
  }
`;

const GenderGroup = styled.div<{ isError?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: ${(props) => props.theme.spaces.s28};

  ${(props) =>
    props.isError &&
    css`
      color: ${props.theme.colors.danger};
    `};
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${ViewModeQueries.MD} {
    flex-direction: row;
    margin: ${(props) => props.theme.spaces.s4} 0 ${(props) => props.theme.spaces.s4} 0;
  }

  div.form-field {
    width: auto;
    margin: ${(props) => props.theme.spaces.s4} 0;

    ${ViewModeQueries.MD} {
      width: 100%;
      max-width: 100%;
      margin: 0;

      &:nth-child(1) {
        margin-right: 10px;
      }

      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
`;

const LegalLink = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary_lighter};

  &:hover {
    text-decoration: underline;
  }
`;

export { Wrap, Group, GenderGroup, LegalLink };
