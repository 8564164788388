import React from 'react';

import { Wrap } from './styled';

import { H3, Text } from '@components/Typography';
import { ReactComponent as SuccessImg } from '@assets/icons/success-registration.svg';
import { NavLink } from '@components/Buttons';

const Success = (props: any) => {
  const { onClose, title, text } = props;
  return (
    <Wrap data-test="success-wrap">
      <H3>{title}</H3>
      <SuccessImg />
      <div>
        <Text size="large">{text}</Text>
      </div>
      <NavLink
        to="/"
        onClick={() => {
          return onClose();
        }}
      >
        Back to homepage
      </NavLink>
    </Wrap>
  );
};

export default Success;
