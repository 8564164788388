import styled, { css } from 'styled-components';

import { NavLink } from 'react-router-dom';

import { ViewModeQueries } from '@helpers/viewModeHelper';

const MenuWidthMD = '375px';

const Divider = styled.div`
  min-width: 100vw;
  position: relative;
  left: -${(props) => props.theme.spaces.s32};
  border-top: 1px solid ${(props) => props.theme.colors.divider};
`;

const TopBar = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: 1fr auto;
  column-gap: ${(props) => props.theme.spaces.s12};
`;

const AuthButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 80px);
  column-gap: ${(props) => props.theme.spaces.s12};
`;

const Avatar = styled.div<{ imageUrl?: string; initials: string }>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary_lightest};
  font-size: ${(props) => props.theme.fonts.h4.fontSize_md};
  line-height: ${(props) => props.theme.fonts.h4.lineHeight_md};
  font-weight: ${(props) => props.theme.fonts.h4.fontWeight};
  color: ${(props) => props.theme.colors.white};
  background-size: cover;

  ${(props) =>
    props.imageUrl
      ? css`
          background-image: url('${props.imageUrl}');
        `
      : css`
          :after {
            content: '${props.initials}';
            position: absolute;
          }
        `};
`;
const UserInfoLink = styled(NavLink)`
  font-size: 12px;
  color: ${(props) => props.theme.colors.primary_lighter};

  ${ViewModeQueries.MD} {
    font-size: 14px;
  }
`;
const UserInfoName = styled.h4`
  font-weight: ${(props) => props.theme.fonts.subtitle1.fontWeight};
  font-size: ${(props) => props.theme.fonts.subtitle1.fontSize_md};
  line-height: ${(props) => props.theme.fonts.subtitle1.lineHeight_md};
  margin-bottom: ${(props) => props.theme.spaces.s4};

  ${ViewModeQueries.MD} {
    font-size: ${(props) => props.theme.fonts.h4.fontSize_md};
    line-height: ${(props) => props.theme.fonts.h4.lineHeight_md};
  }
`;
const UserInfo = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${(props) => props.theme.spaces.s12};
  padding-bottom: ${(props) => props.theme.spaces.s16};

  ${Divider} {
    position: absolute;
    bottom: 0;
  }
`;

const Nav = styled.nav`
  display: grid;
  row-gap: ${(props) => props.theme.spaces.s20};
  margin-top: ${(props) => props.theme.spaces.s12};

  svg {
    width: ${(props) => props.theme.spaces.s24};
    height: ${(props) => props.theme.spaces.s24};
    margin: 0 ${(props) => props.theme.spaces.s8} 0 0;
  }
`;

const Underlay = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;

  ${ViewModeQueries.MD} {
    background: linear-gradient(45.26deg, rgba(86, 204, 242, 0.228) 11.7%, rgba(255, 255, 255, 0) 104.9%),
      linear-gradient(
        248.66deg,
        rgba(251, 133, 0, 0.3) -3.46%,
        rgba(244, 225, 229, 0.3) -3.45%,
        rgba(233, 212, 216, 0.228) 95.08%
      ),
      rgba(255, 255, 255, 0.6);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), inset -1px -0.5px 2px rgba(255, 255, 255, 0.4);
    opacity: 0;
    backdrop-filter: blur(0);
    transition: 0.2s linear;

    ${(props) =>
      props.isOpen &&
      css`
        opacity: 1;
        backdrop-filter: blur(8px);
      `}
  }
`;

const StyledMenu = styled.menu<{ isOpen?: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: ${({ theme }) => `${theme.spaces.s24} ${theme.spaces.s32}`};
  transform: translate(${(props) => (props.isOpen ? 0 : '-100%')});
  transition: 0.4s ease-in-out;

  ${ViewModeQueries.MD} {
    width: ${MenuWidthMD};
  }
`;

const Wrap = styled.div<{ isOpen?: boolean; windowHeight: number }>`
  max-height: ${(props) => props.windowHeight}px;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export { Wrap, Underlay, StyledMenu, TopBar, AuthButtons, UserInfo, UserInfoLink, UserInfoName, Avatar, Nav, Divider };
