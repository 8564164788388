import styled, { css } from 'styled-components';

import { Wrap as BaseWrap } from '../styled';

import { StyledButton, StyledNavLink } from '@components/Buttons/Button/styled';
import { ViewModeQueries } from '@helpers/viewModeHelper';

interface Props {
  visible?: boolean;
}

const InvalidCredentialsBox = styled.div<Props>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border: 2px solid #e44448;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: ${(props) => props.theme.spaces.s20} auto;
  width: 100%;
  max-width: 462px;
  padding: 10px 0;

  ${ViewModeQueries.LG} {
    width: 572px;
    max-width: 572px;
  }

  ${(props) =>
    props.visible !== undefined &&
    css`
      display: ${props.visible === true ? 'flex' : 'none'};
    `};
`;

const ImageSide = styled.div`
  flex: 0 1 auto;
  width: 15%;
  text-align: center;
  align-self: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const TextSide = styled.div`
  flex: 0 1 auto;
  width: 85%;

  .title {
    color: ${(props) => props.theme.colors.danger};
    font-size: ${(props) => props.theme.fonts.h3.fontSize};
    line-height: ${(props) => props.theme.fonts.h3.lineHeight_md};
    font-weight: ${(props) => props.theme.fonts.h3.fontWeight};
  }

  .description {
    font-size: ${(props) => props.theme.fonts.h4.fontSize};
    line-height: ${(props) => props.theme.fonts.h4.lineHeight};
  }
`;

const Wrap = styled(BaseWrap)`
  ${StyledNavLink} {
    margin-top: ${(props) => props.theme.spaces.s20};
  }
`;

const LinksGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${(props) => props.theme.spaces.s8} 0 ${(props) => props.theme.spaces.s20} 0;

  ${StyledButton} {
    margin-top: 0;
  }
`;

export { InvalidCredentialsBox, ImageSide, TextSide, Wrap, LinksGroup };
