import { useEffect, useState } from 'react';

import ConfirmPassword from './ConfirmPassword';
import SignUp from './SignUp';
import SignIn from './SignIn';
import ResetPassword from './ResetPassword';

import { Modal } from '@components/Modals';

export enum AuthScreen {
  SIGN_IN = 'SignIn',
  SIGN_UP = 'SignUp',
  CONFIRM_PASSWORD = 'ConfirmPassword',
  RESET_PASSWORD = 'ResetPassword',
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeScreen?: AuthScreen;
}

const Auth = ({ isOpen, onClose, activeScreen = AuthScreen.SIGN_IN }: Props) => {
  const [screen, setScreen] = useState(activeScreen);
  useEffect(() => {
    setScreen(activeScreen);
  }, [activeScreen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton isContentWidth>
      {screen === AuthScreen.SIGN_IN && (
        <SignIn
          handleResetPassClick={() => setScreen(AuthScreen.RESET_PASSWORD)}
          handleSignUpClick={() => setScreen(AuthScreen.SIGN_UP)}
        />
      )}
      {screen === AuthScreen.SIGN_UP && <SignUp />}
      {screen === AuthScreen.CONFIRM_PASSWORD && <ConfirmPassword />}
      {screen === AuthScreen.RESET_PASSWORD && (
        <ResetPassword handleSignInClick={() => setScreen(AuthScreen.SIGN_IN)} />
      )}
    </Modal>
  );
};

export default Auth;
