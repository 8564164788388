import Select, { Props as SelectProps, OptionProps, IndicatorProps, components } from 'react-select';

import { DropdownIcon, SelectedIcon, Field } from './styled';

import { FieldProps } from '@components/FormElements/BaseFields/Field';

const { Option } = components;

export interface Props extends SelectProps {
  label?: string;
  error?: string;
  fieldProps?: FieldProps;
}

const SelectField = ({
  label,
  error,
  value,
  isDisabled,
  fieldProps,
  onChange,
  onFocus,
  onBlur,
  placeholder = '',
  ...props
}: Props) => {
  return (
    <Select
      classNamePrefix="select"
      menuPlacement="auto"
      value={value}
      placeholder={placeholder}
      isSearchable={false}
      onChange={onChange}
      components={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        SelectContainer: ({ children, hasValue, isFocused }) => (
          <Field
            isFocused={isFocused}
            hasValue={hasValue}
            error={error}
            isDisabled={isDisabled}
            component={<>{children}</>}
            {...fieldProps}
          />
        ),
        IndicatorSeparator: () => null,
        DropdownIndicator: ({ cx }: IndicatorProps<any, any>) => <DropdownIcon {...cx} />,
        Option: ({ children, isSelected, ...optionProps }: OptionProps<any, any>) => (
          <Option {...optionProps} isSelected={isSelected}>
            {children}
            {isSelected && <SelectedIcon />}
          </Option>
        ),
      }}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

export default SelectField;
