import React, { MouseEvent, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';

import { InvalidCredentialsBox, ImageSide, TextSide, Wrap, LinksGroup } from './styled';

import { ReactComponent as BluePinkWarning } from '@assets/icons/blue-pink-warning.svg';
import { H3 } from '@components/Typography';
import PasswordField from '@components/FormElements/FormikFields/Password';
import InputField from '@components/FormElements/FormikFields/Input';
import { addNotificationActionCreator } from '@store/notifications/actions';
import { NotificationType } from '@store/notifications/types';
import { Button, NavLink } from '@components/Buttons/Button';
import Divider from '@components/Divider';
import { FieldsGroup } from '@components/FormElements/styled';
import loginUser from '@services/OAuthServices/loginUser';
import { setOAuthWithUser, setUserId } from '@store/auth/actions';
import { OAuthData } from '@store/auth/types';
import { fetchUserFail, fetchUserSuccess, fetchUserSummary, fetchUserCredits } from '@store/user/actions';
import { setExpirationDate, setRefreshToken, setToken } from '@utils/localStorage';
import { buildDataUserFromMe } from '@utils/profile';
import { calculateExpirationTime } from '@utils/date';
import ME from '@graphql/queries/me';
import { PROMOTIONS_SUMMARY, USER_POINTS } from '@graphql/queries/summary';
import { DataFromMe, DataFromSummary, DataFromUserPoints } from '@/types/user';
import history from '@utils/history';

interface Props {
  handleSignUpClick: (e: MouseEvent) => void;
  handleResetPassClick: (e: MouseEvent) => void;
}
const initialPayload = {
  isAuthenticated: false,
  token: undefined,
  isLogin: false,
  expires: Date.now(),
  refresh: undefined,
  userId: undefined,
};

const SignIn = ({ handleSignUpClick, handleResetPassClick }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean | undefined>(false);
  const [ready, setReady] = useState<boolean | undefined>(false);
  const [readySummary, setReadySummary] = useState<boolean | undefined>(false);
  const [readyCredits, setReadyCredits] = useState<boolean | undefined>(false);
  const [userIdDefined, setUserIdDefined] = useState<string | undefined>(undefined);
  const [loginData, setLoginData] = useState<OAuthData>(initialPayload);
  const [errorCredentials, setErrorCredentials] = useState<boolean | undefined>(false);
  const client = useApolloClient();
  const validationSchema = Yup.object().shape({
    username: Yup.string().email(t('forms.signIn.error.emailFormat')).required(t('forms.signIn.error.emailRequired')),
    password: Yup.mixed().required(t('forms.signIn.error.passwordRequired')),
  });

  useEffect(() => {
    if (ready) {
      client
        .query({
          query: ME,
          fetchPolicy: 'no-cache',
        })
        .then((res: DataFromMe) => {
          const { data } = res;
          const { me } = data;

          dispatch(setUserId(me.id));

          const userPayload = buildDataUserFromMe(res);
          dispatch(fetchUserSuccess({ ...userPayload }));
          dispatch(setOAuthWithUser(loginData));

          setUserIdDefined(me.id);

          return true;
        })
        .catch((err: any) => {
          dispatch(fetchUserFail(err.message));
        });
    }
  }, [ready]);

  useEffect(() => {
    if (userIdDefined) {
      client
        .query({
          query: PROMOTIONS_SUMMARY,
          fetchPolicy: 'no-cache',
          variables: {
            userId: userIdDefined,
          },
        })
        .then((res: DataFromSummary) => {
          const { data } = res;
          const { promotionsSummary } = data;

          dispatch(fetchUserSummary(promotionsSummary));
          setReadySummary(true);

          return true;
        })
        .catch((err: any) => {
          dispatch(fetchUserFail(err.message));
          setReadySummary(true);
        });

      client
        .query({
          query: USER_POINTS,
          fetchPolicy: 'no-cache',
          variables: {
            userId: userIdDefined,
            source: 'point-saz',
          },
        })
        .then((res: DataFromUserPoints) => {
          const { data } = res;
          const { UserPoints } = data;
          const { amount } = UserPoints;

          if (typeof amount === 'number') {
            dispatch(fetchUserCredits(amount));
          }

          setReadyCredits(true);

          return true;
        })
        .catch((err: any) => {
          dispatch(fetchUserFail(err.message));
          setReadyCredits(true);
        });
    }
  }, [userIdDefined]);

  useEffect(() => {
    if (readySummary === true && readyCredits === true) {
      history.push('/');
    }
  }, [readySummary, readyCredits]);

  return (
    <>
      <InvalidCredentialsBox visible={errorCredentials}>
        <ImageSide>
          <BluePinkWarning />
        </ImageSide>
        <TextSide>
          <p className="title">{t('forms.signIn.error.invalidCredentials.title')}</p>
          <p className="description">{t('forms.signIn.error.invalidCredentials.description')}</p>
        </TextSide>
      </InvalidCredentialsBox>
      <Wrap>
        <Formik
          validateOnChange={false}
          initialValues={{
            username: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setErrorCredentials(false);
            setLoading(true);
            const { username, password } = values;

            loginUser({ username, password })
              .then((results) => {
                const { access_token, token_type, refresh_token, expires_in } = results;
                if (access_token && token_type) {
                  const tokenUser = `${token_type} ${access_token}`;
                  const loginPayload = {
                    username,
                    token: tokenUser,
                    isAuthenticated: true,
                    refresh: refresh_token,
                    expires: calculateExpirationTime(expires_in),
                    isLogin: true,
                  };
                  const expirationDate = calculateExpirationTime(expires_in);
                  setToken(tokenUser);
                  setRefreshToken(`${refresh_token}`);
                  setExpirationDate(`${expirationDate}`);
                  setLoginData(loginPayload);

                  return setReady(true);
                }
                const { error } = results;
                if (error === 'invalid_credentials') {
                  setLoading(false);
                  return setErrorCredentials(true);
                }

                return setLoading(false);
              })
              .catch(() => {
                dispatch(
                  addNotificationActionCreator({
                    type: NotificationType.Error,
                    title: t('forms.signIn.error.network'),
                  })
                );
                return setLoading(false);
              });
          }}
        >
          {({ errors }) => (
            <Form>
              <H3>{t('forms.signIn.caption')}</H3>
              <FieldsGroup>
                <InputField
                  name="username"
                  placeholder={t('forms.signIn.placeholder.username')}
                  fieldProps={{
                    label: `${t('forms.signIn.label.username')}*`,
                    isLoading: loading,
                    error: errors.username,
                  }}
                />
                <PasswordField
                  name="password"
                  fieldProps={{
                    label: `${t('forms.signIn.label.password')}*`,
                    isLoading: loading,
                    error: errors.password,
                  }}
                  placeholder={t('forms.signIn.placeholder.password')}
                />
              </FieldsGroup>
              <Button type="submit">{t('forms.signIn.cta.submit')}</Button>
            </Form>
          )}
        </Formik>
        <LinksGroup>
          <Button $type="link" onClick={handleSignUpClick}>
            {t('forms.signIn.cta.signUp')}
          </Button>
          <Button $type="link" data-test="reset-password" onClick={handleResetPassClick}>
            {t('forms.signIn.cta.resetPass')}
          </Button>
        </LinksGroup>
        <Divider> {t('common.OR')}</Divider>
        <NavLink to="/" $type="monavis">
          {t('forms.signIn.cta.monavis')}
        </NavLink>
      </Wrap>
    </>
  );
};

export default SignIn;
