import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { PersistGate } from 'redux-persist/integration/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ApolloWithOAuth from '@services/ApolloWithOAuth';

import { store, persistor } from '@store';
import { routes } from '@router';
import mainTheme from '@styled/themes/mainTheme';
import GlobalStyle from '@styled/globalStyle';
import ErrorBoundary from '@components/ErrorBoundary';
import history from '@utils/history';
import { MenuProvider } from '@layouts/common/Menu';
import Notifications from '@components/Notifications';
import EnglishTranslation from '@utils/location/en';
import SpanishMexicoTranslation from '@utils/location/es-mx';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      ...EnglishTranslation,
    },
    es: {
      ...SpanishMexicoTranslation,
    },
  },
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
});

const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloWithOAuth>
            <ThemeProvider theme={mainTheme}>
              <GlobalStyle />
              <Notifications />
              <MenuProvider>
                <Router history={history}>{renderRoutes(routes)}</Router>
              </MenuProvider>
            </ThemeProvider>
          </ApolloWithOAuth>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
