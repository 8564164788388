import { Nutriscore } from '@/types/nutriscore';
// theme properties naming almost like in BEM, example: camelCaseElement_camelCaseModifier

const baseColors = {
  primary: '#174782',
  primary_light: '#0162AA',
  primary_lighter: '#3FA1EA',
  primary_lightest: '#90CAE5',
  secondary: '#F98524',
  secondary_light: '#FDB62C',
  gray1: '#333333',
  gray2: '#4F4F4F',
  gray3: '#828282',
  gray4: '#BDBDBD',
  gray5: '#E0E0E0',
  gray6: '#F2F2F2',
  stroke: '#F1F2F3',
  info: '#007BE5',
  success: '#3CBD73',
  danger: '#E44448',
  discount: '#E54242',
  white: '#FFFFFF',
  background: '#FBFBFE',
};
const baseFonts = {
  primary: {
    fontFamily: 'Goldplay, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
  },
};

const mainTheme = {
  colors: {
    ...baseColors,
    divider: baseColors.gray6,
    text: {
      default: baseColors.gray1,
      primary: baseColors.primary,
      secondary: baseColors.gray2,
      note: baseColors.gray3,
      disabled: baseColors.gray4,
    },
    input: {
      bg_disabled: '#EEEEEE',
    },
    notifications: {
      bg: baseColors.gray2,
      color: baseColors.white,
    },
    nutrient: {
      [Nutriscore.A]: '#006E2D',
      [Nutriscore.B]: '#83B011',
      [Nutriscore.C]: '#FFBF00',
      [Nutriscore.D]: '#EC6500',
      [Nutriscore.E]: '#DD190A',
    },
    menu: {
      background_menu: '#F1F6FD',
      lightBlue: '#0D64A9',
    },
    order: {
      title: '#00529C',
    },
    button: {
      default: {
        color: baseColors.white,
        color_disabled: baseColors.gray3,
        bg: baseColors.primary_light,
        bg_hover: '#007BD7',
        bg_active: baseColors.primary_light,
        bg_disabled: baseColors.gray6,
        shadow: '#124583',
      },
      secondary: {
        color: baseColors.white,
        color_disabled: baseColors.gray3,
        bg: baseColors.primary_lighter,
        bg_hover: '#89C5F2',
        bg_active: baseColors.primary_lighter,
        bg_disabled: baseColors.gray6,
        shadow: 'rgba(1, 98, 170, 0.6)',
      },
      ghost: {
        color: baseColors.primary_light,
        color_active: baseColors.white,
        color_disabled: baseColors.gray3,
        bg: 'transparent',
        bg_hover: 'rgba(63, 161, 234, 0.08)',
        bg_active: 'rgba(63, 161, 234, 0.16)',
        bg_disabled: 'transparent',
        border: baseColors.primary_light,
      },
      monavis: {
        color: baseColors.white,
        color_disabled: baseColors.gray3,
        bg: '#A0207F',
        bg_hover: '#D069B6',
        bg_active: '#A0207F',
        bg_disabled: baseColors.gray6,
        shadow: '#891A6C',
      },
      link: {
        color: baseColors.primary,
        color_hover: '#007BD7',
        color_disabled: baseColors.gray6,
      },
    },
    credits: {
      add: '#29AE07',
      del: '#E30613',
    },
  },
  borderRadius: {
    r3: '3px',
    r4: '4px',
    r8: '8px',
  },
  spaces: {
    s1: '1px',
    s2: '2px',
    s4: '4px',
    s8: '8px',
    s12: '12px',
    s16: '16px',
    s20: '20px',
    s24: '24px',
    s28: '28px',
    s32: '32px',
    s36: '32px',
    s40: '40px',
    s48: '48px',
    s52: '52px',
    s56: '56px',
    s60: '60px',
    s68: '68px',
    s72: '72px',
    s80: '80px',
  },
  fonts: {
    ...baseFonts,
    h1: {
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '28px',
      fontSize_md: '32px',
      lineHeight_md: '40px',
    },
    h2: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '20px',
      fontSize_md: '28px',
      lineHeight_md: '32px',
    },
    h3: {
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '20px',
      fontSize_md: '24px',
      lineHeight_md: '28px',
    },
    h4: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      fontSize_md: '20px',
      lineHeight_md: '24px',
    },
    subtitle1: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
      fontSize_md: '16px',
      lineHeight_md: '20px',
    },
    subtitle2: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '20px',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '22px',
      fontSize_md: '18px',
      lineHeight_md: '24px',
    },
    body2: {
      fontSize: '12px',
      lineHeight: '18px',
      fontSize_md: '14px',
      lineHeight_md: '20px',
    },
    body3: {
      fontSize: '10px',
      lineHeight: '14px',
      fontSize_md: '12px',
      lineHeight_md: '18px',
    },
    button: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
    },
    input: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  container: {
    padding: '16px',
    padding_md: '32px',
  },
};

export default mainTheme;
