import { gql } from '@apollo/client';

const CREATE_PROFILE = gql`
  mutation createProfile($input: CreateProfileInput!) {
    createProfile(input: $input) {
      id
      gender
      birthDate
      address {
        line1
        line2
        postalCode
        city
        state
      }
      phoneNumber
      educationLevel {
        code
      }
      partner {
        firstName
        gender
        birthDate
      }
      children {
        firstName
        birthDate
      }
      numberOfHomeComponents
      pets {
        type
      }
      preference {
        alcohol
      }
      policies {
        code
        name
      }
    }
  }
`;

export default CREATE_PROFILE;
