import styled from 'styled-components';

import { H3, Paragraph } from '@components/Typography';
import { StyledButton } from '@components/Buttons/Button/styled';
import { ViewModeQueries } from '@helpers/viewModeHelper';

const Content = styled.div`
  ${ViewModeQueries.MD} {
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.colors.background};
    padding: ${(props) => props.theme.spaces.s36} 0;
  }
`;

const Wrap = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray5};
  border-radius: ${(props) => props.theme.borderRadius.r4};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 462px;
  margin: ${(props) => props.theme.spaces.s24} auto;
  padding: ${(props) => props.theme.spaces.s20} ${(props) => props.theme.spaces.s12};

  ${ViewModeQueries.MD} {
    margin: 0 auto;
    padding: 40px 100px;
  }

  ${ViewModeQueries.LG} {
    width: 572px;
    max-width: 572px;
  }

  ${H3} {
    text-align: center;
    margin-bottom: ${(props) => props.theme.spaces.s12};
  }

  ${Paragraph} {
    text-align: center;
    color: ${(props) => props.theme.colors.gray3};
    margin-bottom: ${(props) => props.theme.spaces.s16};
  }

  ${StyledButton} {
    margin-top: ${(props) => props.theme.spaces.s20};
  }
`;

export { Content, Wrap };
