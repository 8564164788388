const PLATFORM: string = 'walmart';
const COUNTRY: string = 'MX';
const LANGUAGE: string = 'es';
const MINIMAL_CHAR_LENGTH: number = 10;
const MINIMAL_AGE: number = 18;

const getPlatform = (): string => PLATFORM;
const getCountry = (): string => COUNTRY;
const getLanguage = (): string => LANGUAGE;
const getMinimalCharLength = (): number => MINIMAL_CHAR_LENGTH;

const getBaseUrl = (): string => {
  return `${window.location.protocol}//${window.location.hostname}`;
};

export { getMinimalCharLength, getPlatform, getCountry, getLanguage, getBaseUrl, MINIMAL_AGE };
