import { Product, ProductDetails } from '@/types/product';
import { ProductsFilterInput } from '@/types/queries';

export enum ActionTypes {
  FETCH_PRODUCTS_START = 'products/FETCH_PRODUCTS_START',
  FETCH_PRODUCTS_SUCCESS = 'products/FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_FAIL = 'products/FETCH_PRODUCTS_FAIL',
  CLEAR_PRODUCTS = 'products/CLEAR_PRODUCTS',
  SET_PRODUCTS_FILTER = 'products/SET_PRODUCTS_FILTER',
  SET_PRODUCTS_SEARCH = 'products/SET_PRODUCTS_SEARCH',
  CLEAR_PRODUCTS_SEARCH = 'products/CLEAR_PRODUCTS_SEARCH',
  FETCH_PRODUCT_DETAILS_START = 'products/FETCH_PRODUCT_DETAILS_START',
  FETCH_PRODUCT_DETAILS_SUCCESS = 'products/FETCH_PRODUCT_DETAILS_SUCCESS',
  FETCH_PRODUCT_DETAILS_FAIL = 'products/FETCH_PRODUCT_DETAILS_FAIL',
}

export type NotificationPayload = Omit<Notification, 'id'>;

export interface State {
  isLoading: boolean;
  error: string;
  items: Product[];
  filter: ProductsFilterInput;
  search?: string;
  details: {
    isLoading: boolean;
    error: string;
    data?: any | null;
  };
}

export interface FetchProductsStartAction {
  type: ActionTypes.FETCH_PRODUCTS_START;
}
export interface FetchProductsSuccessAction {
  type: ActionTypes.FETCH_PRODUCTS_SUCCESS;
  payload: Product[];
}
export interface FetchProductsFailAction {
  type: ActionTypes.FETCH_PRODUCTS_FAIL;
  payload: string;
}

export interface FetchProductDetailsStartAction {
  type: ActionTypes.FETCH_PRODUCT_DETAILS_START;
}
export interface FetchProductDetailsSuccessAction {
  type: ActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS;
  payload: ProductDetails;
}
export interface FetchProductDetailsFailAction {
  type: ActionTypes.FETCH_PRODUCT_DETAILS_FAIL;
  payload: string;
}
export interface ClearProducts {
  type: ActionTypes.CLEAR_PRODUCTS;
}
export interface setProductsFilterAction {
  type: ActionTypes.SET_PRODUCTS_FILTER;
  payload: ProductsFilterInput;
}
export interface setProductsSearchAction {
  type: ActionTypes.SET_PRODUCTS_SEARCH;
  payload: string;
}
export interface clearProductsSearchAction {
  type: ActionTypes.CLEAR_PRODUCTS_SEARCH;
}

export type ActionType =
  | FetchProductsStartAction
  | FetchProductsSuccessAction
  | FetchProductsFailAction
  | FetchProductDetailsStartAction
  | FetchProductDetailsSuccessAction
  | ClearProducts
  | setProductsFilterAction
  | setProductsSearchAction
  | clearProductsSearchAction
  | FetchProductDetailsFailAction;
