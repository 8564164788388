import { getOAuthUrl, getClientSecret } from '@config/getEnviroment';

const urlOAuth = getOAuthUrl();
const clientSecret = getClientSecret();
const getRefreshToken: ({ password }: { password: string | undefined }) => Promise<any | void> = async ({ password }) =>
  fetch(urlOAuth as any, {
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      grant_type: process.env.REACT_APP_REFRESH_GRANT_TYPE,
      client_id: process.env.REACT_APP_CREDENTIALS_CLIENT_ID,
      client_secret: clientSecret,
      scope: process.env.REACT_APP_SCOPE,
      refresh_token: password,
    } as any),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });

export default getRefreshToken;
