import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
`;

const PopUp = styled.div`
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
  width: 100vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 24px rgba(18, 69, 131, 0.16);
  bottom: -100%;
  animation: ${slideUp} 0.4s ease-in-out 0.1s forwards;
`;

export { PopUp };
