import { ReactElement, MouseEvent, ForwardRefRenderFunction, cloneElement, useMemo } from 'react';

import Skeleton from 'react-loading-skeleton';

import useClassNames from '@hooks/useClassNames';
import useRandomElementId from '@hooks/useRandomElementId';
import {
  AddonLeftWrap,
  AddonRightWrap,
  StyledField,
  InputWrap,
  FieldSkeleton,
} from '@components/FormElements/BaseFields/Field/styled';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { FieldError, StyledLabel } from '@components/FormElements/styled';

export type FieldProps = {
  error?: string;
  addonLeft?: ReactElement;
  addonRight?: ReactElement;
  isClearable?: boolean;
  isDisabled?: boolean;
  className?: string;
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
} & (
  | {
      label?: string;
    }
  | {
      label: string;
      id: string;
    }
);

type RenderFunc = (id: string, className: string) => ReactElement;

export type Props = {
  isFocused?: boolean;
  hasValue?: boolean;
  component: ReactElement | RenderFunc;
  renderComponentWrap?: (children: ReactElement, className: string) => ReactElement;
} & FieldProps;

const Field: ForwardRefRenderFunction<HTMLInputElement, Props> = ({
  label,
  error,
  addonLeft,
  addonRight,
  isDisabled,
  className,
  isClearable,
  isFocused,
  hasValue,
  component,
  renderComponentWrap,
  onClear,
  isLoading,
}) => {
  const defaultId = useRandomElementId('input');

  const fieldClassName = useClassNames({
    [className || '']: Boolean(className),
    'form-field-input': true,
    'form-field': true,
    'form-field_focused': isFocused,
    'form-field_disabled': isDisabled,
    'form-field_has-error': Boolean(error),
    'form-field_has-value': hasValue,
  });

  const componentProps = useMemo(() => (typeof component !== 'function' ? component.props : null), [component]);

  const inputClassName = useClassNames({
    [componentProps?.className || '']: Boolean(componentProps?.className),
    'form-field__input': true,
    'form-field__element': true,
  });

  const children = (
    <>
      {addonLeft && <AddonLeftWrap className="form-field__addon form-field__addon-left">{addonLeft}</AddonLeftWrap>}
      {typeof component !== 'function'
        ? cloneElement(component, {
            id: componentProps?.id || defaultId,
            className: inputClassName,
          })
        : component(defaultId, inputClassName)}
      {((isClearable && hasValue) || addonRight) && (
        <AddonRightWrap className="form-field__addon form-field__addon-right">
          {isClearable && hasValue && (
            <button type="button" className="form-field__clearable-btn" onClick={onClear}>
              <CloseIcon />
            </button>
          )}
          {addonRight}
        </AddonRightWrap>
      )}
    </>
  );

  return (
    <StyledField
      className={fieldClassName}
      isFocused={isFocused}
      hasValue={hasValue}
      hasError={Boolean(error)}
      isDisabled={isDisabled}
    >
      {label && (
        <StyledLabel htmlFor={componentProps?.id || defaultId} className="form-field__label">
          {isLoading ? <Skeleton width="50%" /> : label}
        </StyledLabel>
      )}
      {isLoading ? (
        <FieldSkeleton />
      ) : (
        <>
          {renderComponentWrap ? (
            renderComponentWrap(children, 'form-field__input-wrap')
          ) : (
            <InputWrap className="form-field__input-wrap">{children}</InputWrap>
          )}
        </>
      )}
      {error && <FieldError className="form-field__error">{error}</FieldError>}
    </StyledField>
  );
};

export default Field;
