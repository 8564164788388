import loadable from '@loadable/component';

const Root = loadable(() => import(/* webpackChunkName: "Root" */ '@layouts/Root'));
const Home = loadable(() => import(/* webpackChunkName: "Home" */ '@pages/Home'));
const ConfirmEmailPage = loadable(() => import(/* webpackChunkName: "CommentsPage" */ '@pages/ConfirmEmail'));
const Search = loadable(() => import(/* webpackChunkName: "Search" */ '@pages/Search'));
const NotFound = loadable(() => import(/* webpackChunkName: "NotFound" */ '@pages/NotFound'));
const MainLayout = loadable(() => import(/* webpackChunkName: "MainLayout" */ '@layouts/MainLayout'));
const PlainLayout = loadable(() => import(/* webpackChunkName: "PlainLayout" */ '@layouts/PlainLayout'));
const ProfileLayout = loadable(() => import(/* webpackChunkName: "ProfileLayout" */ '@layouts/ProfileLayout'));
const LegalLayout = loadable(() => import(/* webpackChunkName: "LegalLayout" */ '@layouts/Technical/LegalLayout'));
const SupportLayout = loadable(
  () => import(/* webpackChunkName: "SupportLayout" */ '@layouts/Technical/SupportLayout')
);
const AboutUsLayout = loadable(
  () => import(/* webpackChunkName: "AboutUsLayout" */ '@layouts/Technical/AboutUsLayout')
);
const AccountSettings = loadable(() => import(/* webpackChunkName: "Settings" */ '@pages/Profile/AccountSettings'));
const HistoryPage = loadable(() => import(/* webpackChunkName: "HistoryPage" */ '@pages/Profile/History'));
const FavoritesPage = loadable(() => import(/* webpackChunkName: "FavoritesPage" */ '@pages/Profile/Favorites'));
const CommentsPage = loadable(() => import(/* webpackChunkName: "CommentsPage" */ '@pages/Profile/Comments'));
const TelephonePage = loadable(() => import(/* webpackChunkName: "CommentsPage" */ '@pages/Profile/Telephone'));
const ProductPage = loadable(() => import(/* webpackChunkName: "ProductPage" */ '@pages/Product'));
const ProductsPage = loadable(() => import(/* webpackChunkName: "Search" */ '@pages/Products'));
const PromotionsPage = loadable(() => import(/* webpackChunkName: "PromotionName" */ '@pages/Promotions'));
const PromotionPage = loadable(() => import(/* webpackChunkName: "PromotionPage" */ '@pages/Promotion'));
const CouponsPage = loadable(() => import(/* webpackChunkName: "CouponsPage" */ '@pages/Coupons'));
const RedeemCouponPage = loadable(() => import(/* webpackChunkName: "RedeemCouponPage" */ '@pages/RedeemCoupon'));
const CancelCouponPage = loadable(() => import(/* webpackChunkName: "CancelCouponPage" */ '@pages/CancelCoupon'));
const OrderDetailePage = loadable(() => import(/* webpackChunkName: "OrderDetailPage" */ '@pages/Order'));
const CreditsPage = loadable(() => import(/* webpackChunkName: "CouponsPage" */ '@pages/Credits'));
const OrderPage = loadable(() => import(/* webpackChunkName: "OrderPage" */ '@pages/HistoryOrders'));
const CreditsHistoryPage = loadable(() => import(/* webpackChunkName: "OrderPage" */ '@pages/HistoryCredits'));
const CreatePromo = loadable(() => import(/* webpackChunkName: "CreatePromo" */ '@pages/CreatePromo'));
const PostRouter = loadable(() => import(/* webpackChunkName: "PostRouter" */ '@pages/PostRouter'));
const LegacyCommencer = loadable(() => import(/* webpackChunkName: "LegacyCommencer" */ '@pages/LegacyCommencer'));
const WhoWeArePage = loadable(() => import(/* webpackChunkName: "WhoWeArePage" */ '@pages/Technical/AboutUs/WhoWeAre'));
const HowItWorksPage = loadable(
  () => import(/* webpackChunkName: "HowItWorksPage" */ '@pages/Technical/AboutUs/HowItWorks')
);
const FaqPage = loadable(() => import(/* webpackChunkName: "FaqPage" */ '@pages/Technical/Support/Faq'));
const ContactUsPage = loadable(
  () => import(/* webpackChunkName: "ContactUsPage" */ '@pages/Technical/Support/ContactUs')
);
const PrivacyPage = loadable(() => import(/* webpackChunkName: "PrivacyPage" */ '@pages/Technical/Legal/Privacy'));
const TermsOfUsePage = loadable(() => import(/* webpackChunkName: "TermsOfUsePage" */ '@pages/Technical/Legal/Terms'));
const CookiePolicyPage = loadable(
  () => import(/* webpackChunkName: "CookiePolicyPage" */ '@pages/Technical/Legal/Cookie')
);
const SignInPage = loadable(() => import(/* webpackChunkName: "SignInPage" */ '@pages/SignIn'));
const SignInOAuthPage = loadable(() => import(/* webpackChunkName: "SignInPage" */ '@pages/LoginOAuth'));
const SignUpPage = loadable(() => import(/* webpackChunkName: "SignInPage" */ '@pages/SignUp'));
const ResetPasswordPage = loadable(() => import(/* webpackChunkName: "ResetPasswordPage" */ '@pages/ResetPassword'));
const ConfirmPasswordPage = loadable(
  () => import(/* webpackChunkName: "ConfirmPasswordPage" */ '@pages/ConfirmPassword')
);
const PricerHome = loadable(() => import(/* webpackChunkName: "PricerHomePage" */ '@pages/Pricer/Home'));
const routes = [
  {
    component: Root,
    routes: [
      {
        path: [
          '/',
          '/search',
          '/promotions',
          '/my-coupons',
          '/recover-credits',
          '/sign-in',
          '/sign-in-sso',
          '/sign-up',
          '/reset-password',
          '/products',
          '/telephone-form',
        ],
        exact: true,
        component: MainLayout,
        routes: [
          {
            path: '/',
            exact: true,
            component: Home,
            withoutFooter: true,
          },
          {
            path: '/search',
            exact: true,
            component: Search,
            withoutFooter: true,
          },
          {
            path: '/promotions',
            exact: true,
            component: PromotionsPage,
          },
          {
            path: '/products',
            exact: true,
            component: ProductsPage,
          },
          {
            path: '/my-coupons',
            exact: true,
            component: CouponsPage,
          },
          {
            path: '/recover-credits',
            exact: true,
            component: CreditsPage,
          },
          {
            path: '/sign-in',
            exact: true,
            component: SignInPage,
          },
          {
            path: '/sign-in-sso',
            exact: true,
            component: SignInOAuthPage,
          },
          {
            path: '/sign-up',
            exact: true,
            component: SignUpPage,
          },
          {
            path: '/reset-password',
            exact: true,
            component: ResetPasswordPage,
          },
          {
            path: '/telephone-form',
            exact: true,
            component: TelephonePage,
          },
          {
            path: '*',
            exact: true,
            component: NotFound,
          },
        ],
      },
      {
        path: '/resetPassword/:token',
        exact: true,
        component: ConfirmPasswordPage,
        withoutFooter: true,
      },
      {
        path: '/confirmEmail/:token',
        exact: true,
        component: ConfirmEmailPage,
        withoutFooter: true,
      },
      {
        path: '/products/:ean',
        exact: true,
        component: ProductPage,
      },
      {
        path: '/create-order/:userId/:promoId',
        exact: true,
        component: CreatePromo,
      },
      {
        path: '/post-router',
        exact: true,
        component: PostRouter,
      },
      {
        path: '/commencer',
        exact: true,
        component: LegacyCommencer,
      },
      {
        path: '/promotions/:id',
        exact: true,
        component: PromotionPage,
      },
      {
        path: '/redeem-coupon/:id',
        exact: true,
        component: RedeemCouponPage,
      },
      {
        path: '/cancel-coupon/:id',
        exact: true,
        component: CancelCouponPage,
      },
      {
        path: '/promotions/:id/coupon',
        exact: true,
        component: OrderDetailePage,
      },
      {
        path: ['/privacy-policy', '/cookie-policy', '/terms'],
        component: LegalLayout,
        routes: [
          {
            path: '/privacy-policy',
            exact: true,
            component: PrivacyPage,
          },
          {
            path: '/cookie-policy',
            exact: true,
            component: CookiePolicyPage,
          },
          {
            path: '/terms',
            exact: true,
            component: TermsOfUsePage,
          },
          {
            path: '*',
            component: NotFound,
          },
        ],
      },
      {
        path: ['/how-it-works', '/who-we-are'],
        component: AboutUsLayout,
        routes: [
          {
            path: '/how-it-works',
            exact: true,
            component: HowItWorksPage,
          },
          {
            path: '/who-we-are',
            exact: true,
            component: WhoWeArePage,
          },
          {
            path: '*',
            component: NotFound,
          },
        ],
      },
      {
        path: ['/faq', '/contact-us'],
        component: SupportLayout,
        routes: [
          {
            path: '/faq',
            exact: true,
            component: FaqPage,
          },
          {
            path: '/contact-us',
            exact: true,
            component: ContactUsPage,
          },
          {
            path: '*',
            component: NotFound,
          },
        ],
      },
      {
        path: ['/settings', '/history', '/comments', '/favorites', '/coupons-history', '/credits'],
        component: ProfileLayout,
        routes: [
          {
            path: '/settings',
            exact: true,
            component: AccountSettings,
          },
          {
            path: '/history',
            exact: true,
            component: HistoryPage,
          },
          {
            path: '/comments',
            exact: true,
            component: CommentsPage,
          },
          {
            path: '/favorites',
            exact: true,
            component: FavoritesPage,
          },
          {
            path: '/coupons-history',
            exact: true,
            component: OrderPage,
          },
          {
            path: '/credits',
            exact: true,
            component: CreditsHistoryPage,
          },
          {
            path: '*',
            component: NotFound,
          },
        ],
      },
      {
        path: ['/pricer/products/:ean'],
        component: PlainLayout,
        routes: [
          {
            path: '/pricer/products/:ean',
            exact: true,
            component: PricerHome,
          },
          {
            path: '*',
            component: NotFound,
          },
        ],
      },
      {
        path: '*',
        component: MainLayout,
        routes: [
          {
            path: '*',
            component: NotFound,
          },
        ],
      },
    ],
  },
];

export { routes };
