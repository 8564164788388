import React, { FC } from 'react';
import { createUploadLink } from 'apollo-upload-client';
import { RetryLink } from '@apollo/client/link/retry';
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider } from '@apollo/client';

import { getGwUrl } from '@config/getEnviroment';
import RefreshLink from '@services/RefreshLink';
import OAuthLink from '@services/OAuthLink';
import RefreshMiddleware from '@services/RefreshMiddleware';

const ApolloWithOAuth: FC = ({ children }) => {
  const client = new ApolloClient({
    link: ApolloLink.from([
      OAuthLink,
      RefreshLink,
      RefreshMiddleware,
      new RetryLink(),
      createUploadLink({
        uri: getGwUrl(),
        fetchOptions: {
          method: 'POST',
        },
      }),
    ]),
    cache: new InMemoryCache({
      addTypename: true,
      resultCaching: false,
    }),
    name: 'saz-catalog-web-client',
    version: '1.3',
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWithOAuth;
