import React, { MouseEvent, useEffect, useState } from 'react';

import { Form, useFormikContext } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { H3, Paragraph } from '@components/Typography';
import { Button } from '@components/Buttons';
import { FieldsGroup } from '@components/FormElements/styled';
import { Group, LegalLink } from '@components/Auth/SignUp/styled';
import InputField from '@components/FormElements/FormikFields/Input';
import FormikInputMaskField from '@components/FormElements/FormikFields/InputMask';
import PasswordField from '@components/FormElements/FormikFields/Password';
import { FormikInputField, FormikSelectField } from '@components/FormElements/FormikFields';
import MultiRadio from '@components/FormElements/BaseFields/MultiRadio';
import { buildCityOptions, buildStateOptions } from '@utils/options';
import { RegisterUser } from '@/types/user';

interface Props {
  handleSignInClick: (e: MouseEvent) => void;
  isLoading: boolean;
}

const SignUpForm = ({ handleSignInClick, isLoading }: Props) => {
  const { t } = useTranslation();
  const [cityOptions, setCityOptions] = useState<any | undefined>();
  const [stateOptions, setStateOptions] = useState<any | undefined>();
  const [state, setState] = useState<any | undefined>('');

  const { setFieldValue, values, errors } = useFormikContext<RegisterUser>();

  useEffect(() => {
    setStateOptions(buildStateOptions());
  }, []);

  useEffect(() => {
    setCityOptions(buildCityOptions(state));
  }, [state]);

  const radioFemale = {
    value: 'FEMALE',
    label: t('option.gender.FEMALE'),
  };
  const radioMale = {
    value: 'MALE',
    label: t('option.gender.MALE'),
  };
  const radioContent = [radioFemale, radioMale];
  const labelRadio = `${t('forms.signUp.label.gender')}  (*)`;
  const nameRadio = 'gender';

  return (
    <>
      <Form>
        <H3>{t('forms.signUp.caption')}</H3>
        <>
          <FieldsGroup>
            <MultiRadio
              radioValues={radioContent}
              name={nameRadio}
              label={labelRadio}
              error={errors.gender}
              setFieldValue={setFieldValue}
              selectedValue={values.gender}
              isLoading={isLoading}
            />
            <Group>
              <InputField
                name="firstName"
                fieldProps={{ label: `${t('forms.signUp.label.firstName')} (*)`, isLoading }}
                placeholder={t('forms.signUp.placeholder.firstName')}
              />
              <InputField
                name="lastName"
                fieldProps={{ label: `${t('forms.signUp.label.lastName')} (*)`, isLoading }}
                placeholder={t('forms.signUp.placeholder.lastName')}
              />
            </Group>
            <Group>
              <InputField
                name="email"
                fieldProps={{ label: `${t('forms.signUp.label.email')} (*)`, isLoading }}
                placeholder={t('forms.signUp.placeholder.email')}
              />
              <PasswordField
                name="password"
                fieldProps={{ label: `${t('forms.signUp.label.password')} (*)`, isLoading }}
                placeholder={t('forms.signUp.placeholder.password')}
              />
            </Group>
            <Group>
              <FormikInputMaskField
                name="birthDate"
                mask="99/99/9999"
                placeholder={t('forms.signUp.placeholder.birthDate')}
                fieldProps={{ label: `${t('forms.signUp.label.birthDate')} (*)`, isLoading }}
              />
            </Group>
            <Group>
              <FormikSelectField
                name="state"
                placeholder={t('forms.signUp.placeholder.state')}
                options={stateOptions}
                onChange={(option) => {
                  setFieldValue('state', option?.value);
                  setState(option?.value);
                }}
                fieldProps={{ label: `${t('forms.signUp.label.state')} (*)`, isLoading }}
              />
              <FormikSelectField
                name="city"
                placeholder={t('forms.signUp.placeholder.city')}
                options={cityOptions}
                fieldProps={{ label: `${t('forms.signUp.label.city')} (*)`, isLoading }}
              />
            </Group>
            <Group>
              <FormikInputMaskField
                name="zipCode"
                mask="99999"
                placeholder={t('forms.signUp.placeholder.zipCode')}
                fieldProps={{ label: `${t('forms.signUp.label.zipCode')} (*)`, isLoading }}
              />
              <FormikInputField
                name="line1"
                placeholder={t('forms.signUp.placeholder.line1')}
                fieldProps={{ label: `${t('forms.signUp.label.line1')} (*)`, isLoading }}
              />
            </Group>
            <Group>
              <FormikInputField
                name="line2"
                placeholder={t('forms.signUp.placeholder.line2')}
                fieldProps={{ label: `${t('forms.signUp.label.line2')}`, isLoading }}
              />
              <div className="form-field" />
            </Group>
            <Paragraph>
              <Trans i18nKey="forms.signUp.cta.title">
                Al hacer clic en «Aceptar y unirse», aceptas las
                <LegalLink to="conditions">Condiciones de uso</LegalLink>, la
                <LegalLink to="privacy-policy">Política de privacidad</LegalLink> y la
                <LegalLink to="cookies-policy">Política de cookies</LegalLink> de Shopadvizor.
              </Trans>
            </Paragraph>
          </FieldsGroup>
        </>
        <FieldsGroup>
          <Button type="submit">{t('forms.signUp.cta.submit')}</Button>
          <Button $type="ghost" onClick={handleSignInClick}>
            {t('forms.signUp.cta.logIn')}
          </Button>
        </FieldsGroup>
      </Form>
    </>
  );
};

export default SignUpForm;
