import { History } from '@/types/history';

export enum ActionTypes {
  FETCH_HISTORY_START = 'history/FETCH_HISTORY_START',
  FETCH_HISTORY_SUCCESS = 'history/FETCH_HISTORY_SUCCESS',
  FETCH_HISTORY_FAIL = 'history/FETCH_HISTORY_FAIL',
}

export interface State {
  isLoading: boolean;
  error: string;
  items: History[];
}

export interface FetchHistoryStartAction {
  type: ActionTypes.FETCH_HISTORY_START;
}
export interface FetchHistorySuccessAction {
  type: ActionTypes.FETCH_HISTORY_SUCCESS;
  payload: History[];
}
export interface FetchHistoryFailAction {
  type: ActionTypes.FETCH_HISTORY_FAIL;
  payload: string;
}

export type ActionType = FetchHistoryStartAction | FetchHistorySuccessAction | FetchHistoryFailAction;
