import styled from 'styled-components';

import { inputStyles } from '@components/FormElements/BaseFields/Field/styled';
import { ReactComponent as ArrowIcon } from '@assets/icons/arrow.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/checkbox-vector.svg';
import BaseField, { Props } from '@components/FormElements/BaseFields/Field';

const DropdownIcon = styled(ArrowIcon)`
  transform: rotate(90deg);
  width: 18px;
  height: 18px;

  path {
    stroke: ${(props) => props.theme.colors.gray2};
  }
`;
const SelectedIcon = styled(CheckIcon)`
  path {
    stroke: ${(props) => props.theme.colors.primary_lighter};
  }
`;

export interface StyledFieldProps {
  hasError?: boolean;
  hasValue?: boolean;
  isDisabled?: boolean;
}
const Wrap = styled.div<StyledFieldProps>`
  width: 100%;
`;

const Field = styled(BaseField)<Props>`
  position: relative;

  .select__value-container {
    padding: 0 ${(props) => props.theme.spaces.s16};
  }

  // control
  .select__control {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
  }
  .select__value-container {
    ${inputStyles};

    position: relative;
  }

  // indicators
  .select__indicators {
    padding-right: ${(props) => props.theme.spaces.s16};
  }

  // menu
  .select__menu {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: none;
    color: ${(props) => props.theme.colors.gray2};
    font-size: ${(props) => props.theme.fonts.body2.fontSize_md};
    line-height: ${(props) => props.theme.fonts.body2.lineHeight_md};

    &-list {
      padding-top: ${(props) => props.theme.spaces.s16};
      padding-bottom: ${(props) => props.theme.spaces.s16};
    }
    .select__option {
      cursor: pointer;
      padding: ${(props) => `${props.theme.spaces.s12} ${props.theme.spaces.s20}`};
      background-color: transparent;
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      justify-content: space-between;
      column-gap: ${(props) => props.theme.spaces.s12};
      color: ${(props) => props.theme.colors.gray2};

      &--is-focused,
      &--is-selected {
        background-color: ${(props) => props.theme.colors.background};
        color: ${(props) => props.theme.colors.gray1};
      }
      &--is-selected {
        font-weight: 600;
      }
    }
  }
`;

export { Wrap, DropdownIcon, SelectedIcon, Field };
