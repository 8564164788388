import { ApolloLink } from '@apollo/client';

import { getToken } from '@utils/localStorage';

const RefreshMiddleware = new ApolloLink((operation, forward) => {
  const apolloToken = getToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: apolloToken || null,
    },
  }));

  return forward(operation);
});

export default RefreshMiddleware;
