export enum ActionTypes {
  AUTHORIZE_USER = 'auth/AUTHORIZE_USER',
  AUTHORIZE_CLIENT = 'auth/AUTHORIZE_CLIENT',
  LOGOUT_USER = 'auth/LOGOUT_USER',
  SET_USERID = 'auth/SET_USERID',
}

export interface OAuthData {
  token: string | undefined;
  refresh?: string;
  isLogin?: boolean;
  isAuthenticated?: boolean;
  expires: number;
  userId?: string;
}

export interface AuthorizeUserAction {
  type: ActionTypes.AUTHORIZE_USER;
  payload: OAuthData;
}
export interface SetUserId {
  type: ActionTypes.SET_USERID;
  payload: string;
}
export interface AuthorizeClientAction {
  type: ActionTypes.AUTHORIZE_CLIENT;
  payload: OAuthData;
}

export interface LogoutUserAction {
  type: ActionTypes.LOGOUT_USER;
}

export type ActionType = AuthorizeClientAction | AuthorizeUserAction | LogoutUserAction | SetUserId;
