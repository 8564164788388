import { ActionType, ActionTypes, State } from './types';

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  filter: {},
  search: undefined,
  details: {
    isLoading: false,
    error: '',
    data: undefined,
  },
};

const productsReducer = (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case ActionTypes.FETCH_PRODUCTS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    case ActionTypes.FETCH_PRODUCTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.FETCH_PRODUCT_DETAILS_START:
      return {
        ...state,
        details: {
          ...state.details,
          data: undefined,
          isLoading: true,
        },
      };
    case ActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          data: action.payload,
        },
      };
    case ActionTypes.CLEAR_PRODUCTS:
      return {
        ...state,
        items: [],
      };
    case ActionTypes.FETCH_PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        details: {
          ...state.details,
          isLoading: false,
          error: action.payload,
        },
      };
    case ActionTypes.SET_PRODUCTS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case ActionTypes.SET_PRODUCTS_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case ActionTypes.CLEAR_PRODUCTS_SEARCH:
      return {
        ...state,
        search: undefined,
      };
    default:
      return state;
  }
};

export default productsReducer;
