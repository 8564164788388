import * as Yup from 'yup';
import i18n from 'i18next';

enum ErrorMessages {
  InvalidEmail = 'Invalid email format',
  Required = 'This field is required',
  PasswordMismatch = 'Password does not match',
}

const email = (isRequired?: boolean, errorMsg: string = ErrorMessages.InvalidEmail) => {
  const validation = Yup.string().test('customEmail', errorMsg, (value) => {
    return value
      ? /^[a-z0-9!#$%&'*+=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
          value
        )
      : true;
  });
  return isRequired ? validation.required(i18n.t('validation.error.required')) : validation;
};

const required = Yup.mixed().required(ErrorMessages.Required);

export { ErrorMessages, email, required };
