import React, { InputHTMLAttributes, useCallback, useState } from 'react';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';

import InputField, { Props } from '@components/FormElements/BaseFields/Input';
import { ReactComponent as ShowIcon } from '@assets/icons/show.svg';
import { ReactComponent as HideIcon } from '@assets/icons/hide.svg';

interface EyeProps {
  hasValue: boolean;
  isVisible: boolean;
  onClick?: () => void;
}

const Eye = ({ hasValue, isVisible, onClick }: EyeProps) => {
  return (
    <button type="button" onClick={onClick}>
      {hasValue && (isVisible ? <ShowIcon /> : <HideIcon />)}
    </button>
  );
};

const PasswordField = ({ name, fieldProps, ...props }: Props & InputHTMLAttributes<HTMLInputElement>) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible((prevState) => !prevState), []);

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const { error, touched } = form.getFieldMeta(field.name);
        return (
          <InputField
            type={isPasswordVisible ? 'text' : 'password'}
            fieldProps={{
              addonRight: (
                <Eye hasValue={!!field.value} isVisible={isPasswordVisible} onClick={togglePasswordVisibility} />
              ),
              error: touched ? error : undefined,
              ...fieldProps,
            }}
            {...field}
            {...props}
            name={name}
          />
        );
      }}
    </Field>
  );
};

export default PasswordField;
