import { ActionType, ActionTypes, State } from './types';

const initialSummary = {
  promotionsAvailable: undefined,
  ordersRequested: undefined,
  ordersExpiredAndRedeemed: undefined,
};

const initialState = {
  isLoading: false,
  error: '',
  credits: undefined,
  data: undefined,
  summary: initialSummary,
};

const userReducer = (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case ActionTypes.FETCH_USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case ActionTypes.FETCH_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CLEAN_USER:
      return initialState;
    case ActionTypes.FETCH_USER_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case ActionTypes.FETCH_USER_CREDITS:
      return {
        ...state,
        credits: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
