import { Promotion } from '@/types/promotion';

export enum ActionTypes {
  FETCH_PROMOTIONS_START = 'promotions/FETCH_PROMOTIONS_START',
  FETCH_PROMOTIONS_SUCCESS = 'promotions/FETCH_PROMOTIONS_SUCCESS',
  FETCH_PROMOTIONS_FAIL = 'promotions/FETCH_PROMOTIONS_FAIL',
  CHANGE_PAGE = 'promotions/CHANGE_PAGE',
  SET_SELECTED_PROMOTION = 'promotions/SET_SELECTED_PROMOTION',
  CLEAR_PROMOTIONS = 'promotions/CLEAR_PROMOTIONS',
  ADD_SELECTED_PROMOTION = 'promotions/ADD_SELECTED_PROMOTION',
}

export interface State {
  isLoading: boolean;
  error: string;
  items: Promotion[];
  page: number;
  totalItems: number;
  details: {
    isLoading: boolean;
    error: string;
    data?: Promotion | null;
  };
  selected?: Promotion | undefined;
}

export interface FetchPromotionsStartAction {
  type: ActionTypes.FETCH_PROMOTIONS_START;
}
export interface FetchPromotionsSuccessAction {
  type: ActionTypes.FETCH_PROMOTIONS_SUCCESS;
  payload: Promotion[];
  totalItems: number;
}
export interface FetchPromotionsFailAction {
  type: ActionTypes.FETCH_PROMOTIONS_FAIL;
  payload: string;
}

export interface ChangePage {
  type: ActionTypes.CHANGE_PAGE;
  payload: number;
}

export interface AddSelectedPromotion {
  type: ActionTypes.ADD_SELECTED_PROMOTION;
  payload: Promotion;
}

export interface SetSelectedPromotion {
  type: ActionTypes.SET_SELECTED_PROMOTION;
  payload: Promotion;
}

export interface ClearPromotions {
  type: ActionTypes.CLEAR_PROMOTIONS;
}

export type ActionType =
  | FetchPromotionsStartAction
  | FetchPromotionsSuccessAction
  | FetchPromotionsFailAction
  | ChangePage
  | SetSelectedPromotion
  | AddSelectedPromotion
  | ClearPromotions;
