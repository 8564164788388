import { NotificationType } from '@store/notifications/types';
import { ReactComponent as SuccessIcon } from '@assets/icons/notify-success.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/notify-info.svg';
import { ReactComponent as WarningIcon } from '@assets/icons/notify-warning.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/notify-error.svg';

interface Props {
  type?: NotificationType;
}

const Icon = ({ type }: Props) => {
  switch (type) {
    case NotificationType.Success:
      return <SuccessIcon />;
    case NotificationType.Info:
      return <InfoIcon />;
    case NotificationType.Warning:
      return <WarningIcon />;
    case NotificationType.Error:
      return <ErrorIcon />;
    default:
      return null;
  }
};

export default Icon;
