import { ActionType, ActionTypes, State } from './types';

const FIRST_PAGE = 1;
const INITIAL_TOTAL_ITEMS = 0;

const initialState = {
  isLoading: false,
  error: '',
  items: [],
  totalItems: INITIAL_TOTAL_ITEMS,
  page: FIRST_PAGE,
  details: {
    isLoading: false,
    error: '',
    data: undefined,
  },
  selected: undefined,
};

const ordersReducers = (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case ActionTypes.FETCH_ORDERS_BY_USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ORDERS_BY_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: [...state.items, ...action.payload],
        totalItems: action.totalItems,
      };
    case ActionTypes.FETCH_ORDERS_BY_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CLEAR_ORDERS:
      return {
        ...state,
        items: [],
      };
    case ActionTypes.SAVE_SELECTED_ORDER:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};

export default ordersReducers;
