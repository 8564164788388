import { ButtonHTMLAttributes } from 'react';

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import useClassNames from '@hooks/useClassNames';
import { StyledCloseButton } from '@components/Buttons/CloseButton/styled';

const CLoseButton = ({ className, ...restProps }: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const classNames = useClassNames({
    'close-btn': true,
    [className || '']: Boolean(className),
  });

  return (
    <StyledCloseButton type="button" className={classNames} aria-label="Close" {...restProps}>
      <CloseIcon />
    </StyledCloseButton>
  );
};

export default CLoseButton;
