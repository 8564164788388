import { ChangeEvent, FocusEvent, useState, useEffect, forwardRef, ForwardRefRenderFunction } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import useRandomElementId from '@hooks/useRandomElementId';
import Field, { FieldProps } from '@components/FormElements/BaseFields/Field';

export interface Props extends InputMaskProps {
  fieldProps?: FieldProps;
}

const InputMaskField: ForwardRefRenderFunction<InputMask, Props> = (
  { onFocus, onBlur, onChange, value, fieldProps, ...rest },
  ref
) => {
  const id = useRandomElementId('input');
  const [isFocused, setIsFocused] = useState(false);
  const [innerValue, setInnerValue] = useState(value || '');

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (value === undefined) setInnerValue(e.target.value);
    if (onChange) onChange(e);
  };

  useEffect(() => {
    setInnerValue(value || '');
  }, [value]);

  return (
    <Field
      hasValue={Boolean(innerValue)}
      isFocused={isFocused}
      component={
        <InputMask
          ref={ref}
          id={id}
          className="field-input-element form-field__input"
          type="text"
          value={innerValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          {...rest}
        />
      }
      {...fieldProps}
    />
  );
};

export default forwardRef(InputMaskField);
