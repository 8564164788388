import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Nav,
  Underlay,
  StyledMenu,
  Divider,
  TopBar,
  AuthButtons,
  UserInfo,
  Avatar,
  UserInfoLink,
  UserInfoName,
} from './styled';

import { ActionButton, ActionNavLink } from '@components/Buttons/ActionButton';
import { Button, CloseButton } from '@components/Buttons';
import { logOut } from '@store/auth/actions';
import { fetchUserStart, cleanUser } from '@store/user/actions';
import { userId as userIdSelector, isLogin as isAuthenticatedSelector } from '@store/auth/selectors';
import { userDataSelector } from '@store/user/selectors';

import { ReactComponent as PromotionsIcon } from '@assets/icons/promotions.svg';
import { ReactComponent as CouponsIcon } from '@assets/icons/coupons.svg';
import { ReactComponent as EvaluationsIcon } from '@assets/icons/evaluations.svg';
import { ReactComponent as HowItWorksIcon } from '@assets/icons/how-it-works.svg';
import { ReactComponent as RetailersIcon } from '@assets/icons/retailers.svg';
import { ReactComponent as MessageIcon } from '@assets/icons/message.svg';
import { ReactComponent as InfoCircleIcon } from '@assets/icons/info-circle.svg';
import { ReactComponent as PaperIcon } from '@assets/icons/paper.svg';
import { ReactComponent as CookiesIcon } from '@assets/icons/cookies.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/logout_danger.svg';

import useOutsideClick from '@hooks/useOutsideClick';
import useWindowHeight from '@hooks/useWindowHeight';
import Auth from '@components/Auth';
import { FIRST_CHAR } from '@utils';
import { setExpirationDate, setRefreshToken, setToken } from '@utils/localStorage';
import loginClient from '@services/OAuthServices/loginClient';
import { calculateExpirationTime } from '@utils/date';
import history from '@utils/history';

interface Context {
  isOpen: boolean;
  toggleMenu: (isOpen?: boolean) => any;
}

const MenuContext = React.createContext<Context>({
  isOpen: false,
  toggleMenu: () => {},
});

const MenuProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = useCallback((isMenuOpen?: boolean) => {
    setIsOpen((prevState) => (isMenuOpen !== undefined ? isMenuOpen : !prevState));
  }, []);

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        toggleMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

const useMenu = () => {
  return {
    ...useContext(MenuContext),
  };
};

const Menu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef(null);
  const { isOpen, toggleMenu } = useMenu();
  const [isOpenAuthModal, setIsAuthModal] = useState(false);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userId = useSelector(userIdSelector);
  const user = useSelector(userDataSelector);
  const windowHeight = useWindowHeight();
  const hasId = userId !== undefined;
  const userInfoPayload = {
    src: user?.photo,
    screenName: `${user?.firstName} ${user?.lastName}`,
    initials: `${user?.firstName?.charAt(FIRST_CHAR).toUpperCase()}${user?.lastName?.charAt(FIRST_CHAR).toUpperCase()}`,
  };

  const handleSignUp = () => {
    history.push('/sign-up');
    toggleMenu(false);
  };
  const handleSignIn = () => {
    history.push('/sign-in');
    toggleMenu(false);
  };

  const handleLogout = () => {
    dispatch(fetchUserStart());
    loginClient()
      .then((token) => {
        const { token_type, access_token, expires_in } = token;
        const tokenRefreshed = `${token_type} ${access_token}`;
        const expirationDate = calculateExpirationTime(expires_in);
        setToken(tokenRefreshed);
        setExpirationDate(`${expirationDate}`);
        setRefreshToken('');
        dispatch(logOut());
        dispatch(cleanUser());
        toggleMenu(false);
        return history.push('/');
      })
      .catch(() => {
        setToken('');
        setExpirationDate('');
        setRefreshToken('');
        dispatch(logOut());
        dispatch(cleanUser());
        toggleMenu(false);
        return history.push('/');
      });
  };

  useOutsideClick(ref, () => toggleMenu(false), ['menu-closable']);

  return (
    <Wrap isOpen={isOpen} windowHeight={windowHeight}>
      <Auth isOpen={isOpenAuthModal} onClose={() => setIsAuthModal(false)} />
      <Underlay isOpen={isOpen} />
      <StyledMenu ref={ref} isOpen={isOpen}>
        <div>
          <TopBar>
            {isAuthenticated && hasId ? (
              <UserInfo>
                <Avatar imageUrl={userInfoPayload.src} initials={userInfoPayload.initials} />
                <div>
                  <UserInfoName>{userInfoPayload?.screenName}</UserInfoName>
                  <UserInfoLink to="/settings" className="menu-closable" data-test="btn-profile">
                    {t('aside.myAccount')}
                  </UserInfoLink>
                </div>
              </UserInfo>
            ) : (
              <AuthButtons>
                <Button data-test="btn-login" className="menu-closable" $type="secondary" onClick={handleSignIn}>
                  Sign in
                </Button>
                <Button data-test="btn-sign-up" className="menu-closable" onClick={handleSignUp}>
                  Sign up
                </Button>
              </AuthButtons>
            )}
            <CloseButton onClick={() => toggleMenu()} />
          </TopBar>
          <Nav>
            <Divider />
            <ActionNavLink className="menu-closable" size="small" icon={<PromotionsIcon />} to="/promotions">
              {t('aside.promotions')}
            </ActionNavLink>
            <ActionNavLink className="menu-closable" size="small" icon={<CouponsIcon />} to="/my-coupons">
              {t('aside.coupons')}
            </ActionNavLink>
            <ActionNavLink className="menu-closable" size="small" icon={<EvaluationsIcon />} to="/recover-credits">
              {t('aside.evaluations')}
            </ActionNavLink>
            <Divider />
            <ActionNavLink className="menu-closable" size="small" icon={<HowItWorksIcon />} to="/how-it-works">
              {t('aside.howItWorks')}
            </ActionNavLink>
            <ActionNavLink className="menu-closable" size="small" icon={<RetailersIcon />} to="/shops">
              {t('aside.shops')}
            </ActionNavLink>
            <Divider />
            <ActionNavLink className="menu-closable" size="small" icon={<MessageIcon />} to="/contact-us">
              {t('aside.contact')}
            </ActionNavLink>
            <ActionNavLink className="menu-closable" size="small" icon={<InfoCircleIcon />} to="/faq">
              {t('aside.faq')}
            </ActionNavLink>
            <ActionNavLink className="menu-closable" size="small" icon={<PaperIcon />} to="/terms">
              {t('aside.termsAndConditions')}
            </ActionNavLink>
            <ActionNavLink className="menu-closable" size="small" icon={<PaperIcon />} to="/privacy-policy">
              {t('aside.privacy')}
            </ActionNavLink>
            <ActionNavLink className="menu-closable" size="small" icon={<CookiesIcon />} to="/cookie-policy">
              {t('aside.cookies')}
            </ActionNavLink>
            <Divider />
            <ActionButton
              color="#E44448"
              data-test="btn-logout"
              size="small"
              icon={<LogoutIcon />}
              onClick={() => handleLogout()}
            >
              {t('aside.logout')}
            </ActionButton>
          </Nav>
        </div>
      </StyledMenu>
    </Wrap>
  );
};

export { Menu as default, useMenu, MenuContext, MenuProvider };
