import React from 'react';
import { Field } from 'formik';
import { FieldProps } from 'formik/dist/Field';

import SelectField, { Props } from '@components/FormElements/BaseFields/Select';

interface Option {
  label: string;
  value: string;
}

const FormikSelectField = ({ name, fieldProps, isMulti, options, onChange, value, ...props }: Props) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<readonly Option[] | Option | null>) => {
        const { error, touched } = form.getFieldMeta(field.name);
        return (
          <SelectField
            {...props}
            {...field}
            onBlur={(e) => {
              if (props.onBlur) {
                props.onBlur(e);
              }
              field.onBlur(e);
            }}
            value={
              // eslint-disable-next-line no-nested-ternary
              value !== undefined
                ? value
                : // eslint-disable-next-line no-nested-ternary
                options
                ? isMulti
                  ? options.filter((option) => (field.value as Option[])?.indexOf(option.value) >= 0)
                  : options.find((option) => option.value === field.value)
                : isMulti
                ? []
                : ('' as any)
            }
            onChange={(option, action) => {
              if (onChange) {
                onChange(option, action);
              } else {
                form.setFieldValue(
                  field.name,
                  isMulti
                    ? (option as unknown as Option[])?.map((item: Option) => item.value)
                    : (option as Option).value
                );
              }
            }}
            name={name}
            options={options}
            fieldProps={{ error: touched ? error : undefined, ...fieldProps }}
            isMulti={isMulti}
          />
        );
      }}
    </Field>
  );
};

export default FormikSelectField;
