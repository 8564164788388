import { FormikValues } from 'formik';

import { getCountry, getPlatform } from '@utils/config';
import { User } from '@/types/user';

interface SignUpFromForm {
  password: string;
  firstName: string;
  lastName: string;
  email: string;
}
const getSignUpFormValues = (props: FormikValues): SignUpFromForm => {
  const { password, firstName, lastName, email } = props;
  return {
    password,
    firstName,
    lastName,
    email,
  };
};
const buildSignUpParameters = (props: SignUpFromForm) => {
  const { password, firstName: name, lastName, email: mail } = props;
  const user = {
    username: mail,
    password,
    email: mail,
    name,
    lastName,
    conditions: true,
    dataPolicy: true,
    communicationsPolicy: false,
    platform: getPlatform(),
    country: getCountry(),
  };
  return { user, options: { sendEmail: false } };
};

const buildUpdateUserParameters = (props: { data?: User; telephone: string }) => {
  const { data, telephone } = props;
  const userId = data?.userId;
  const firstName = data?.firstName;
  const lastName = data?.lastName;
  const mail = data?.email;

  const user = {
    username: mail,
    name: firstName,
    lastName,
    active: true,
    telephone,
  };
  return { id: userId, input: { ...user } };
};

export { buildSignUpParameters, getSignUpFormValues, buildUpdateUserParameters };
