import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Formik, Form, FormikValues } from 'formik';
import * as Yup from 'yup';

import { Wrap } from '../styled';

import { required } from '@utils/validation';
import { H3, H4, Paragraph } from '@components/Typography';
import { Button } from '@components/Buttons/Button';

import PasswordField from '@components/FormElements/FormikFields/Password';
import { FieldsGroup } from '@components/FormElements/styled';
import { addNotificationActionCreator } from '@store/notifications/actions';
import { NotificationType } from '@store/notifications/types';

import history from '@utils/history';
import RESET_PASSWORD from '@graphql/mutations/resetPassword';
import EXPIRE_TOKEN from '@graphql/mutations/expireToken';

const validationSchema = Yup.object().shape({
  password: required,
  confirmedPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match!")
    .required('Required'),
});

const ConfirmPassword = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [validToken, setValidToken] = useState<boolean | undefined>();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [expireToken, { loading: loadingReset }] = useMutation(EXPIRE_TOKEN);

  useLayoutEffect(() => {
    const variables = {
      token,
    };
    expireToken({ variables: { ...variables } })
      .then(() => {
        return setValidToken(true);
      })
      .catch(() => {
        setValidToken(false);
      });
  }, []);

  return (
    <Wrap>
      {validToken === false && (
        <>
          <H3>{t('forms.confirmPassword.caption')}</H3>
          <H4>{t('forms.confirmPassword.captionError')}</H4>
        </>
      )}
      {validToken !== false && (
        <Formik
          initialValues={{
            password: '',
            confirmedPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values: FormikValues) => {
            const variables = {
              token,
              password: values?.password,
            };
            resetPassword({ variables: { ...variables } })
              .then((res) => {
                if (res) {
                  dispatch(
                    addNotificationActionCreator({
                      type: NotificationType.Success,
                      title: t('forms.confirmPassword.notification.success'),
                    })
                  );
                  history.push('/');
                }
                return res;
              })
              .catch(() => {
                dispatch(
                  addNotificationActionCreator({
                    type: NotificationType.Error,
                    title: t('forms.confirmPassword.notification.error'),
                  })
                );
              });
          }}
        >
          <Form>
            <H3>{t('forms.confirmPassword.caption')}</H3>
            <Paragraph size="small">{t('forms.confirmPassword.subCaption')}</Paragraph>
            <FieldsGroup>
              <PasswordField
                name="password"
                fieldProps={{ label: t('forms.confirmPassword.label.password'), isLoading: loading || loadingReset }}
                placeholder={t('forms.confirmPassword.placeholder.password')}
              />
              <PasswordField
                name="confirmedPassword"
                fieldProps={{ label: t('forms.confirmPassword.label.confirm'), isLoading: loading || loadingReset }}
                placeholder={t('forms.confirmPassword.placeholder.confirm')}
              />
            </FieldsGroup>
            <Button type="submit">{t('forms.confirmPassword.cta.submit')}</Button>
          </Form>
        </Formik>
      )}
    </Wrap>
  );
};

export default ConfirmPassword;
