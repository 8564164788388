import { ActionType, ActionTypes, State } from './types';

const initialState = {
  items: [],
};

const notificationsReducer = (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case ActionTypes.ADD_NOTIFICATION:
      return {
        items: [...state.items, action.payload],
      };
    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        items: state.items.filter((n) => n.id !== action.payload),
      };
    default:
      return state;
  }
};

export default notificationsReducer;
