import React, { PropsWithChildren, ReactElement, SVGProps } from 'react';

import { StyledActionButtonProps } from './styled';

export interface BaseProps extends StyledActionButtonProps {
  icon?: ReactElement<SVGProps<SVGSVGElement>>;
}

export type ActionButtonProps<T> = PropsWithChildren<BaseProps & T>;

export default function useActionButton<T = unknown>({ icon, children, ...rest }: ActionButtonProps<T>) {
  return {
    ...rest,
    icon,
    children: (
      <>
        {icon}
        {children}
      </>
    ),
  };
}
