import styled, { css, keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ReactComponent as LoaderIcon } from '@assets/icons/loader.svg';
import { ViewModeQueries } from '@helpers/viewModeHelper';

// loader
const rotate = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled(LoaderIcon)`
  display: none;
  position: absolute;
  margin: auto;
  height: 50%;
  animation: ${rotate} 0.8s linear infinite;
`;

export type IconAlign = 'left' | 'right' | 'default';

const Icon = styled.span<{ align?: IconAlign }>`
  font-size: 0;
  line-height: 0;

  ${(props) =>
    props.align === 'left' &&
    css`
      margin-right: ${props.theme.spaces.s8};
      order: -1;
    `}

  ${(props) =>
    props.align === 'right' &&
    css`
      margin-left: ${props.theme.spaces.s8};
    `}
`;

const Content = styled.span`
  white-space: nowrap;
`;

export type Type = 'default' | 'secondary' | 'ghost' | 'monavis' | 'link';

export interface StyledButtonProps {
  $type?: Type;
  isInline?: boolean;
  isIconOnly?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

// default
const buttonPrimaryStyles = css<StyledButtonProps>`
  color: ${(props) => props.theme.colors.button.default.color};
  background-color: ${(props) => props.theme.colors.button.default.bg};

  ${(props) =>
    props.isDisabled
      ? css`
          cursor: not-allowed;
          color: ${props.theme.colors.button.default.color_disabled};
          background-color: ${props.theme.colors.button.default.bg_disabled};
        `
      : css`
          &:hover {
            background-color: ${props.theme.colors.button.default.bg_hover};
          }
          &:active {
            background-color: ${props.theme.colors.button.default.bg_active};
            box-shadow: inset 0 4px 4px ${props.theme.colors.button.default.shadow};
          }
        `};
`;

// secondary
const buttonSecondaryStyles = css<StyledButtonProps>`
  color: ${(props) => props.theme.colors.button.secondary.color};
  background-color: ${(props) => props.theme.colors.button.secondary.bg};

  ${(props) =>
    props.isDisabled
      ? css`
          color: ${props.theme.colors.button.secondary.color_disabled};
          background-color: ${props.theme.colors.button.secondary.bg_disabled};
        `
      : css`
          &:hover {
            background-color: ${props.theme.colors.button.secondary.bg_hover};
          }
          &:active {
            background-color: ${props.theme.colors.button.secondary.bg_active};
            box-shadow: inset 0 4px 4px ${props.theme.colors.button.secondary.shadow};
          }
        `};
`;

// ghost
const buttonGhostStyles = css<StyledButtonProps>`
  color: ${(props) => props.theme.colors.button.ghost.color};
  background-color: ${(props) => props.theme.colors.button.ghost.bg};

  ${(props) =>
    props.isDisabled
      ? css`
          color: ${props.theme.colors.button.ghost.color_disabled};
          background-color: ${props.theme.colors.button.ghost.bg_disabled};
        `
      : css`
          &:hover {
            background-color: ${props.theme.colors.button.ghost.bg_hover};
          }
          &:active {
            background-color: ${props.theme.colors.button.ghost.bg_active};
          }
        `};
`;

// monavis
const buttonMonavisStyles = css<StyledButtonProps>`
  color: ${(props) => props.theme.colors.button.monavis.color};
  background-color: ${(props) => props.theme.colors.button.monavis.bg};

  ${(props) =>
    props.isDisabled
      ? css`
          color: ${props.theme.colors.button.monavis.color_disabled};
          background-color: ${props.theme.colors.button.monavis.bg_disabled};
        `
      : css`
          &:hover {
            background-color: ${props.theme.colors.button.monavis.bg_hover};
          }
          &:active {
            background-color: ${props.theme.colors.button.monavis.bg_active};
            box-shadow: inset 0 4px 4px ${props.theme.colors.button.monavis.shadow};
          }
        `};
`;

// link
const buttonLinkStyles = css<StyledButtonProps>`
  height: auto;
  padding: 0;
  color: ${(props) => props.theme.colors.button.link.color};
  background-color: transparent;

  ${(props) =>
    props.isDisabled
      ? css`
          color: ${props.theme.colors.button.link.color_disabled};
          background-color: transparent;
        `
      : css`
          &:hover {
            color: ${props.theme.colors.button.link.color_hover};
            background-color: transparent;
          }
        `};

  ${ViewModeQueries.MD} {
    height: auto;
  }
`;

const buttonStyles = css<StyledButtonProps>`
  position: relative;
  width: ${(props) => (props.isInline ? 'auto' : '100%')};
  height: 40px;
  padding: 0 ${(props) => props.theme.spaces.s24};
  display: ${(props) => (props.isInline ? 'inline-flex' : 'flex')};
  align-items: center;
  justify-content: center;
  transition: 0.2s linear;
  border-radius: ${(props) => props.theme.borderRadius.r4};
  font-size: ${(props) => props.theme.fonts.button.fontSize};
  line-height: ${(props) => props.theme.fonts.button.lineHeight};
  font-weight: ${(props) => props.theme.fonts.button.fontWeight};

  ${(props) =>
    props.isIconOnly &&
    css`
      ${Icon} {
        margin: 0;
      }
    `}

  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;

      ${Loader} {
        display: inline;
      }
      ${Content}, ${Icon} {
        visibility: hidden;
      }
    `};

  ${ViewModeQueries.MD} {
    height: 48px;
  }

  // primary
  ${(props) => (props.$type === 'default' || props.$type === undefined) && buttonPrimaryStyles};

  // secondary
  ${(props) => props.$type === 'secondary' && buttonSecondaryStyles};

  // ghost
  ${(props) => props.$type === 'ghost' && buttonGhostStyles};

  // monavis
  ${(props) => props.$type === 'monavis' && buttonMonavisStyles};

  // link
  ${(props) => props.$type === 'link' && buttonLinkStyles};
`;

const StyledButton = styled.button`
  ${buttonStyles};
`;

const StyledNavLink = styled(NavLink)`
  ${buttonStyles};

  text-decoration: none;
`;

const StyledLink = styled.a`
  ${buttonStyles};

  text-decoration: none;
  line-height: inherit;
`;

export { StyledButton, StyledLink, StyledNavLink, Icon, Loader, Content };
