import { MutableRefObject, useLayoutEffect } from 'react';

function useOutsideClick(
  ref: MutableRefObject<HTMLElement | null>,
  handler: (e: Event) => void,
  exceptClassNames: string[] = [],
  when: boolean = true
): void {
  function handle(e: Event) {
    const target = e.target as Element;
    const className = target && target.getAttribute('class');

    if (
      ref &&
      ref.current &&
      (!ref.current.contains(target) ||
        (exceptClassNames && exceptClassNames.some((c) => className && className.indexOf(c) !== -1)))
    ) {
      handler(e);
    }
  }
  useLayoutEffect(() => {
    if (when) {
      document.addEventListener('click', handle);
      document.addEventListener('ontouchstart', handle);
    }
    return () => {
      document.removeEventListener('click', handle);
      document.removeEventListener('ontouchstart', handle);
    };
  }, [ref, handler, when]);
}

export default useOutsideClick;
