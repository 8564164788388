import {
  ChangeEvent,
  FocusEvent,
  useState,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  TextareaHTMLAttributes,
} from 'react';

import { Counter, Field } from './styled';

import { FieldProps } from '@components/FormElements/BaseFields/Field';
import { TextareaWrap } from '@components/FormElements/BaseFields/Field/styled';

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  fieldProps?: FieldProps;
  limit?: number;
}

const TextareaField: ForwardRefRenderFunction<HTMLTextAreaElement, Props> = (
  { onFocus, onBlur, onChange, value, fieldProps, limit, rows, ...rest },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);
  const [innerValue, setInnerValue] = useState(value || '');

  const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (value === undefined) setInnerValue(limit !== undefined ? e.target.value.slice(0, limit) : e.target.value);
    if (onChange) onChange(e);
  };

  useEffect(() => {
    let val = value || '';
    if (typeof value !== 'number' && value !== undefined && limit !== undefined) val = value.slice(0, limit);
    setInnerValue(val);
  }, [value]);

  return (
    <Field
      hasValue={Boolean(innerValue)}
      hasLimit={limit !== undefined}
      isFocused={isFocused}
      renderComponentWrap={(children, className) => (
        <TextareaWrap rows={rows} className={`${className} textarea-wrap`}>
          {children}
        </TextareaWrap>
      )}
      component={(id, className) => (
        <>
          {limit && (
            <Counter>
              {typeof innerValue !== 'number' ? innerValue.length || 0 : 0} / {limit}
            </Counter>
          )}
          <textarea
            id={id}
            ref={ref}
            className={`${className} field-input-element form-field__textarea`}
            value={innerValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            {...rest}
          />
        </>
      )}
      {...fieldProps}
    />
  );
};

export default forwardRef(TextareaField);
