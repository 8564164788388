import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import history from './history/reducer';
import comments from './comments/reducer';
import products from './products/reducer';
import promotions from './promotions/reducer';
import orders from './orders/reducer';
import favorites from './favorites/reducer';
import notifications from './notifications/reducer';

const rootReducer = combineReducers({
  auth,
  user,
  history,
  comments,
  products,
  promotions,
  orders,
  favorites,
  notifications,
});

export default rootReducer;
