import { Dispatch } from 'redux';

import { ActionTypes, NotificationPayload, RemoveNotificationAction } from './types';

let id = 0;

const addNotificationActionCreator = (payload: NotificationPayload) => (dispatch: Dispatch) => {
  id += 1;

  dispatch({
    type: ActionTypes.ADD_NOTIFICATION,
    payload: {
      id,
      ...payload,
    },
  });
};

const removeNotificationActionCreator = (index: number): RemoveNotificationAction => ({
  type: ActionTypes.REMOVE_NOTIFICATION,
  payload: index,
});

export { addNotificationActionCreator, removeNotificationActionCreator };
