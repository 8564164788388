import React, { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { Wrap } from '../styled';

import SEND_RESET_PASSWORD from '@graphql/mutations/sendResetPassword';
import { H3, Paragraph } from '@components/Typography';
import InputField from '@components/FormElements/FormikFields/Input';
import { FieldsGroup } from '@components/FormElements/styled';
import { Button } from '@components/Buttons';
import { addNotificationActionCreator } from '@store/notifications/actions';
import { NotificationType } from '@store/notifications/types';
import { getBaseUrl } from '@utils/config';
import { email } from '@utils/validation';

const validationSchema = Yup.object().shape({
  email: email(true),
});

interface Props {
  handleSignInClick: (e: MouseEvent) => void;
}

const ResetPassword = ({ handleSignInClick }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sendResetPassword, { loading }] = useMutation(SEND_RESET_PASSWORD);

  return (
    <Wrap>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values: FormikValues) => {
          const variables = {
            input: {
              username: values?.email,
              landingPageUrl: `${getBaseUrl()}/resetPassword/:token:`,
            },
          };
          sendResetPassword({ variables: { ...variables } })
            .then((res) => {
              if (res) {
                dispatch(
                  addNotificationActionCreator({
                    type: NotificationType.Success,
                    title: t('forms.resetPassword.notification.success'),
                  })
                );
              }
              return res;
            })
            .catch(() => {
              dispatch(
                addNotificationActionCreator({
                  type: NotificationType.Error,
                  title: t('forms.resetPassword.notification.error'),
                })
              );
            });
        }}
      >
        <Form>
          <H3>{t('forms.resetPassword.caption')}</H3>
          <Paragraph size="small">{t('forms.resetPassword.subCaption')}</Paragraph>
          <InputField
            name="email"
            fieldProps={{ label: `${t('forms.resetPassword.label.email')}*`, isLoading: loading }}
            placeholder={t('forms.resetPassword.placeholder.email')}
          />
          <FieldsGroup>
            <Button type="submit">{t('forms.resetPassword.cta.submit')}</Button>
            <Button $type="ghost" onClick={handleSignInClick}>
              {t('forms.resetPassword.cta.back')}
            </Button>
          </FieldsGroup>
        </Form>
      </Formik>
    </Wrap>
  );
};

export default ResetPassword;
