import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { FormFieldError } from './styled';

import { Subtitle } from '@components/Typography';
import { GenderGroup } from '@components/Auth/SignUp/styled';
import RadioField from '@components/FormElements/BaseFields/RadioGroup';
import { FieldSkeleton } from '@components/FormElements/BaseFields/Field/styled';

interface RadioValues {
  label: string;
  value: string;
}

const MultiRadio = (props: {
  radioValues: RadioValues[];
  name: string;
  label: string;
  error?: string;
  setFieldValue: Function;
  selectedValue: string;
  isLoading: boolean;
}) => {
  const { radioValues, name, label, setFieldValue, selectedValue, error, isLoading } = props;
  const isError = Boolean(error);

  if (isLoading) {
    return (
      <>
        <Skeleton width="50%" />
        <FieldSkeleton />
      </>
    );
  }

  return (
    <>
      <Subtitle size="small" type={isError ? 'error' : 'default'}>
        {label}
      </Subtitle>
      <GenderGroup>
        {radioValues.map((radio: RadioValues) => (
          <RadioField
            key={radio.value}
            label={radio.label}
            labelSide="right"
            name={name}
            value={radio.value}
            checked={selectedValue === radio.value}
            error={error}
            onChange={() => {
              setFieldValue(name, radio.value);
            }}
          />
        ))}
      </GenderGroup>
      {isError && <FormFieldError>{error}</FormFieldError>}
    </>
  );
};

export default MultiRadio;
