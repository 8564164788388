import React from 'react';

import { Wrap, Content, Border } from './styled';

const Divider: React.FunctionComponent = ({ children }) => {
  return (
    <Wrap>
      <Border />
      <Content>{children}</Content>
      <Border />
    </Wrap>
  );
};

export default Divider;
