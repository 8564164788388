const SpanishMexicoTranslation = {
  translation: {
    common: {
      OR: 'or',
      upToDate: 'El contenido esta actualizado',
      visitSite: 'Go to the site',
      assessment: {
        excellent: 'Excellent',
        veryGood: 'Very good',
        good: 'Good',
        acceptable: 'Acceptable',
        bad: 'Bad',
      },
      back: 'Volver',
      cancel: 'Cancelar',
      clear: 'Borrar',
      apply: 'Aplicar',
      send: 'Enviar',
      noItemsFound: 'Sorry we can not find any items.',
      day: '{{n}} día',
      days: '{{n}} días',
      today: 'hoy',
      tomorrow: 'mañana',
    },
    validation: {
      error: {
        invalidEmail: 'Formato de correo electrónico inválido',
        invalidBirthDate: 'La fecha introducida no es válida. Debes ser mayor de edad',
        required: 'Este campo es obligatorio',
        passwordMismatch: 'Las contraseñas no coinciden',
        numbersNotAllowed: 'Números no permitidos',
      },
    },
    option: {
      gender: {
        choose: 'Elige uno',
        MALE: 'Hombre',
        FEMALE: 'Mujer',
        NOT_ANSWERED: '-',
      },
      policies: {
        platformCommunications: 'Platform Communications',
        retailerCommunications: 'Retailer Communications',
        dataLinkWithSAZ: 'Link profile with ShopAdvizor',
        thirdPartyCommunications: 'Third Party Communications',
        telephoneSazCommunications: 'SAZ telephone communications',
        telephoneOthersCommunications: 'Walmart Aurrera telephone communications',
      },
      educationLevel: {
        0: 'Level 0: Pre-primary education',
        1: 'Level 1: Primary education or first stage of basic education',
        2: 'Level 2: Lower secondary education or second stage of basic education',
        3: 'Level 3: Upper secondary education',
        4: 'Level 4: Post-secondary non-tertiary education',
        5: 'Level 5: Short-cycle tertiary education',
        6: "Level 6: Bachelor's or equivalent",
        7: "Level 7: Master's or equivalent",
        8: 'Level 8: Doctoral or equivalent',
      },
      yesOrNot: {
        YES: 'Yes',
        NO: 'No',
        NOT_ANSWERED: '-',
      },
      preference: {
        alcohol: 'Alcohol',
        bio: 'Bio',
        light: 'Light',
        noGluten: 'Without gluten',
      },
      numberOfHomeComponent: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        '5+': 'More than 5',
      },
    },
    aside: {
      myAccount: 'Mi cuenta',
      promotions: 'Promociones disponibles',
      coupons: 'Mis cupones',
      evaluations: 'Recuperar créditos',
      howItWorks: '¿Cómo funciona?',
      shops: 'Tiendas adscritas',
      contact: 'Contacto',
      faq: 'Preguntas frecuentes',
      termsAndConditions: 'Términos y condiciones',
      privacy: 'Política de privacidad',
      cookies: 'Política de cookies',
      logout: 'Cerrar sesión',
    },
    forms: {
      signIn: {
        caption: 'Iniciar sesión',
        label: {
          username: 'Correo electrónico',
          password: 'Contraseña',
        },
        placeholder: {
          username: 'Correo electrónico',
          password: 'Contraseña',
        },
        cta: {
          signUp: 'Crea tu cuenta',
          resetPass: 'Olvidé mi contraseña',
          monavis: 'Connect through Monavislerendgratuit.com',
          submit: 'Iniciar sesión',
        },
        error: {
          invalid: 'Los datos introducidos son incorrectos.',
          passwordRequired: 'Debes introducir tu contraseña',
          emailRequired: 'Debes rellenar tu dirección de correo electrónico',
          emailFormat:
            'El correo electrónico que has introducido no coincide con el formato de una dirección de correo electrónico',
          network: 'Network error. Please try again in a few moments.',
          invalidCredentials: {
            title: 'Los datos introducidos son incorrectos.',
            description:
              'Vuelve a intentarlo o selecciona “Olvidé mi contraseña” en caso hayas olvidado tu contraseña y quieras cambiarla.',
          },
        },
      },
      signUp: {
        caption: 'Crear cuenta',
        label: {
          email: 'Correo electrónico',
          password: 'Contraseña',
          firstName: 'Nombre',
          lastName: 'Apellido paterno',
          gender: 'Sexo',
          birthDate: 'Fecha de nacimiento',
          phoneNumber: 'Número de teléfono móvil',
          state: 'Estado',
          city: 'Ciudad',
          zipCode: 'Código postal',
          line1: 'Calle y número',
          line2: 'Colonia',
        },
        placeholder: {
          email: 'Correo electrónico',
          password: 'Contraseña',
          firstName: 'Nombre',
          lastName: 'Apellido paterno',
          birthDate: 'dd/mm/aaaa',
          phoneNumber: 'Número de teléfono móvil',
          state: 'Estado',
          city: 'Ciudad',
          zipCode: 'Código postal',
          line1: 'Calle y número',
          line2: 'Núm Interior, Referencias, Entrada...',
        },
        cta: {
          title:
            'Al hacer clic en «Aceptar y unirse», aceptas las <1>Condiciones de uso</1>, la <3>Política de privacidad</3> y la <5>Política de cookies</5> de Shopadvizor.',
          logIn: 'Already registered?, Log in',
          submit: 'Aceptar y unirse',
        },
        error: {
          network: 'Network error',
        },
      },
      profile: {
        headers: {
          policies: 'Policies',
          preferences: 'Preferences',
          education: 'Education',
          couple: 'Couple',
          pet: 'Pet',
        },
        label: {
          gender: 'Gender',
          partnerGender: 'Gender',
          partnerFirstName: 'First Name',
          partnerBirthDate: 'Birth date',
          numberOfHomeComponents: 'Number of home components',
          state: 'State',
          city: 'City',
          zipCode: 'Zip code',
          line1: 'Address',
          line2: 'Address complementary',
          hasPartner: 'hasPartner',
          hasPet: 'hasPartner',
          educationLevelCode: '',
          birthDate: 'Date of birth',
        },
        placeholder: {
          gender: 'Gender',
          partnerGender: 'Your partner gender',
          partnerFirstName: 'Your partner firstname',
          partnerBirthDate: 'Your partner lastname',
          numberOfHomeComponents: 'Number of people who you live with',
          state: 'State',
          city: 'City',
          zipCode: 'Zip code',
          line1: 'Enter your address',
          line2: 'Enter your additional info',
          hasPartner: 'Do you have a partner?',
          hasPet: 'Do you have a pet?',
          educationLevelCode: 'What are your educational level?',
          birthDate: 'Date of birth',
        },
        cta: {
          logIn: 'Already registered?, Log in',
          submit: 'Create your account',
        },
        error: {
          network: 'Network error',
        },
        telephone: {
          title: 'Teléfono',
          change: 'Editar',
          confirm: 'Confimar',
          message: {
            noPhone: 'xx-xxxxxxxx',
          },
        },
      },
      photo: {
        description: {
          title: 'Change your avatar image',
          subtitle1: 'It must be jpg,png',
          subtitle2: 'it must be below 2.5M',
        },
        cta: {
          add: 'Add photo',
          edit: 'Edit photo',
          delete: 'Delete photo',
        },
        caption: {
          file: 'Change your avatar',
          submit: 'Save changes',
        },
        notification: {
          success: 'Your photo has changed',
          error: 'Error, connection failed',
        },
      },
      telephone: {
        description: {
          title: 'Confirma tu número de teléfono',
          text: 'Introduce el número de teléfono con el que te diste de alta',
        },
        caption: {
          telephone: {
            label: 'Número de teléfono',
            placeholder: 'Número de teléfono sin código de país.',
          },
          submit: 'Número de teléfono',
        },
        notification: {
          success: 'Your has changed',
          error: 'Error, connection failed',
        },
      },
      telephoneToken: {
        description: {
          title: '¡Ya estamos casi!',
          text: {
            WithPhone: 'Te hemos enviado un mensaje con un código de verificación al *** *** **',
            Send: 'Introduce el código para continuar e inicia sesión con tus credenciales.',
          },
        },
        caption: {
          telephone: {
            label: 'Número de teléfono',
            placeholder: 'Número de teléfono sin código de país.',
          },
          submit: 'Aceptar',
          reSend: 'Volver a enviar',
        },
        notification: {
          success: 'Tu teléfono  ha sido confirmado con exito.',
          successSend: 'Le hemos reenviado el código de nuevo.',
          error: 'Error, código inválido.',
        },
      },
      resetPassword: {
        caption: 'Ayuda de contraseña',
        subCaption:
          'Introduce tu dirección de correo electrónico asociada con tu cuenta Shopadvizor y te enviaremos un código de verificación y el proceso para restablecer tu contraseña.',
        captionError: 'Not valid token.',
        label: {
          email: 'Correo electrónico',
        },
        placeholder: {
          email: 'Correo electrónico',
        },
        notification: {
          success: 'El correo electrónico se ha enviado correctamente.',
          error: 'Error, connection failed',
        },
        cta: {
          submit: 'Continuar',
          back: 'Back to login',
        },
      },
      confirmPassword: {
        caption: 'Crear contraseña',
        subCaption:
          'Para completar la renovación de tu contraseña, introduce una contraseña nueva. Te pediremos esta contraseña siempre que inicies sesión.',
        captionError: 'El token es inválido.',
        label: {
          password: 'Nueva contraseña',
          confirm: 'Confirma tu contraseña',
        },
        placeholder: {
          password: 'Nueva contraseña',
          confirm: 'Confirma tu contraseña',
        },
        notification: {
          success: 'Tu contraseña se ha cambiado con éxito',
          error: 'Error, connection failed',
        },
        cta: {
          submit: 'Guardar los cambios',
        },
      },
      commentCreate: {
        cta: {
          leaveComment: 'Create a review',
        },
        error: {
          noRate: 'You must rate the product',
          commentBelowMinimum: 'Minimal comment length 10 characters.',
        },
      },
    },
    pages: {
      confirmEmail: {
        caption: 'Confirma tu email',
        success: {
          title: '¡Tu email ha sido confirmado!',
          text: '',
        },
        error: {
          title: 'Tu email aún no esta confirmado',
        },
      },
      home: {
        banner: {
          title: '¡Canjea créditos por productos!',
          helpTextOne: 'Elige la promoción que más te interesa y canjéala por tus créditos.',
          helpTextTwo: ' Retira de tu tienda los productos, contesta una breve encuesta y sigue eligiendo productos.',
          helpTextThree: 'Con Shopadvizor, haz que tu opinion cuente mas que nunca.',
          subtitle: 'Prueba productos gratis a cambio de tu opinión.',
          discover: 'Descubre',
          rate: 'opina',
          save: 'ahorra',
          howItWorks: '¿Cómo funciona?',
        },
      },
      promotions: {
        router: {
          postSurveySuccess: 'Gracias por completar la encuesta',
          createCouponSuccess: 'Tu cupón se ha generado correctamente',
          error: 'Lo sentimos, ha ocurrido un error, por favor, intentalo más tarde',
          creating: 'Estamos creando tu cupón, por favor espera unos segundos',
        },
        menu: {
          available: 'Promociones disponibles',
          coupons: 'Mis cupones',
          couponsHistory: 'Histórico de cupones',
          orders: 'Recuperar créditos',
          profile: 'Mi perfil',
          history: 'Histórico',
          credits: 'Saldo de créditos',
          closeSession: 'Cerrar sesión',
          totalCredits: 'créditos',
        },
        list: {
          title: '¿Qué productos quieres llevarte hoy?',
          activePromotion: {
            plural: '{{total}} promociones activas',
            singular: 'Una promoción activa',
          },
          showingQuantity: 'Mostrando {{showing}} de {{total}} promociones',
          showMore: 'Mostrar más',
        },
        card: {
          conditions: 'Términos y condiciones',
          validUntil: 'Valido hasta',
          availableRetailers: 'Sólo disponible en',
          requestCoupon: 'Solicitar cupón',
          loadingRequest: 'Cargando, por favor, espera',
          legalText:
            ' Para beneficiarse de esta promoción, debe realizar una compra minima de 30€. Puede utilizar un máximo de 4 cupones diferentes por compra realizada.',
          credits: 'créditos',
          activePromotions: 'Promociones Activas',
          loginMessage: 'Para beneficiarte de la promoción has de estar logueado.',
          couponCode: 'Código de cupón',
          evaluate: 'Opinar',
          expired: 'Expirado',
          exchange: 'Canjear',
          coupons: 'Mis Cupones',
          free: 'Gratis',
        },
        labels: {
          noStock: 'Agotado',
          lastUnits: 'Últimas',
        },
        concepts: {
          createOrder: 'Solicitud: {{name}}',
          cancelOrder: 'Cancelación: {{name}}',
        },
      },
      coupons: {
        title: 'Mis cupones',
        emptyMainText: 'En esta pantalla encontrarás tus cupones solicitados.',
        subText:
          'Ahora mismo no tienes cupones esperando a ser redimidos. No dudes en elegir tus productos favoritos en "Promociones disponibles".',
        helpTextOne:
          'Para obtener el cupón que le permite retirar el producto seleccionado, simplemente haga clic sobre la tarjeta del producto.',
        helpTextTwo:
          'Si no va a utilizar el cupón, no dude en cancelarlo indicando claramente el motivo, pudiendo recuperar sus créditos.',
        helpTextThree:
          'Si ya ha recogido el producto en tienda y quiere evaluarlo para recuperar sus créditos, entre en el detalle del cupón y haga clic en "Ya lo he redimido".',
        card: {
          productCode: 'Código de producto',
          couponCode: 'Código de cupón',
          validUntil: 'Válido hasta',
          availableRetailers: 'Disponible en tiendas',
          alreadyRedeemed: 'Ya lo he redimido',
          print: 'Imprimir',
          cancelCoupon: 'Anular cupón',
          savedTo: 'Oferta reservada a',
          phone: 'Número de teléfono móvil asociado',
          email: 'Email asociado',
          legalText:
            'Este cupón (junto con todos los anexos) está estrictamente reservado para el uso de la persona o entidad a quien va dirigida. Puede contener información privilegiada y confidencial. Si no es usted el destinatario indicado, cualquier divulgación, distribución o copia de este mensaje está estrictamente prohibida.',
        },
        redeem: {
          title: '¿Ya has retirado el producto?',
          confirmText: 'Para confirmar que has canjeado el cupón, por favor, complete la siguiente información:',
          dateSelector: '¿Cuando fuiste a la tienda?',
          validate: 'Validar',
          waitMessage: 'Validando. Por favor, espera...',
          retailerOptions: '¿En que tienda retiraste el producto?',
          errors: {
            NO_DATE: 'Por favor, selecciona una fecha',
            DATE_IS_THE_FUTURE: 'La fecha no puede ser en el futuro',
            NO_RETAILER: 'Por favor, selecciona un retailer',
          },
          successRedeemToastMessage: 'Tu promoción ha sido evaluada y tus créditos han sido devueltos correctamente.',
          errorRedeemToastMessage: 'Lo sentimos, ha habido un error al evaluar tu promoción.',
        },
        cancel: {
          title: '¿Deseas anular el cupón?',
          subtitle: 'Para anular el cupón, por favor, dinos por qué:',
          notFoundOption: 'No encontré el producto en la tienda',
          forgotOption: 'Fui a la tienda pero olvidé llevarme el producto',
          dontWantOption: 'Decidí no canjear el cupón',
          couponNotWorking: 'El cupón no funcionó y no me dieron el descuento',
          otherReasons: 'Otras razones',
          waitMessage: 'Cancelando. Por favor, espera...',
          expired: 'El cupón expiró y no lo he usado',
          cancelReasonLabel: 'Razón (mínimo 10 carácteres)',
          cancelButton: 'Anular',
          successCancelToastMessage: 'Tu cupón ha sido cancelado y tus créditos han sido devueltos correctamente.',
          errorCancelToastMessage: 'Lo sentimos, ha habido un error al cancelar tu cupón.',
        },
      },
      couponsHistory: {
        title: 'Histórico de cupones',
        requestDate: 'Fecha solicitado',
        redemptionDate: 'Fecha de redención',
        couponCode: 'Código cupón',
        retailer: 'Tienda',
        credits: 'créditos',
        loadingMore: 'Cargando tu historial, espera un momento por favor',
        notCouponsMessage: 'Todavia no tienes ningún cupón canjeado',
      },
      creditsHistory: {
        title: 'Saldo de créditos',
        loadingMore: 'Cargando tu historial, espera un momento por favor',
        noData: 'Todavía no tienes movimientos en tus créditos',
        header: {
          date: 'Fecha',
          concept: 'Concepto',
          credits: 'Créditos',
        },
        pagination: {
          showingPageOf: 'Mostrando página {{actual}} de {{total}}',
          next: 'Siguiente >',
          prev: '< Anterior',
        },
      },
      credits: {
        title: 'Recuperar créditos',
        mainText: 'En esta pantalla encontrarás tus cupones solicitados',
        helpTextOne: 'Evalúa los productos que ya has probado y RECUPERA TUS CRÉDITOS.',
        helpTextTwo:
          'Tómate unos minutos para calificar el producto que has probado y recupera los créditos utilizados durante la compra de ese producto.',
        helpTextThree: 'Gracias por responder de la manera más objetiva y sincera posible, te estamos agradecidos.',
        helpTextFour:
          'La evaluación estará disponible dentro de las 48 horas posteriores a la recogida del producto en la tienda.',
      },
      promotion: {
        back: 'atrás',
        ean: 'EAN de producto',
      },
      productDetail: {
        caption: {
          ctaToolTipStarsBefore: 'Hey, hey,',
          ctaToolTipStarsAfter: 'you can touch the stars!',
          ingredients: 'Ingredients',
          allergens: 'Allergens',
          description: 'Description',
          nutrients: 'Nutrients',
          moreInfo: 'More information',
          ratingDetails: 'Rating details',
          productRating: 'Current product rating',
          userReviews: 'User reviews',
          nutriscore: 'About nutriscore',
          range: 'Nutrients range',
          totalReviews: '(Total reviews)',
        },
        texts: {
          ratingDetails:
            'Basically, the nutritional value for the our grade is determined by subtracting the total ' +
            'positive points from the total negative points. And the lower the score, the better the letter grade.',
          nutriscore:
            'Voici comment est attribué le Nutri-Score, un algorithme attribue des points à chaque élément repris dans le ' +
            'tableau des valeurs nutritionnelles (par 100 g ou 100 ml), tant pour les substances alimentaires bénéfiques ' +
            'affichant un score négatif (protéines, fibres, quantité de fruits, de légumes, de fruits à coque, d&apos;huile ' +
            'de colza, d&apos;huile de noix et d&apos;huile d&apos;olive) que pour les substances alimentaires moins ' +
            'bénéfiques affichant un score positif (haute valeur énergétique [kJ], sucres, acides gras saturés, sel).' +
            'Ensuite, les points positifs (moins bénéfiques, donc) sont déduits des points négatifs (bénéfiques) et le' +
            'résultat est converti dans le tableau du Nutri-Score. ',
        },
      },
      successSignUp: {
        caption: {
          title: 'Your account has been created!',
        },
        texts: {
          success: 'We’ve sent an email with a link. Check your email!',
        },
      },
    },
  },
};

export default SpanishMexicoTranslation;
