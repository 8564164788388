import { TokenRefreshLink } from 'apollo-link-oauth-token-refresh';

import { getToken, setRefreshToken, setToken } from '@utils/localStorage';
import loginClient from '@services/OAuthServices/loginClient';

const isValidClientToken = (): boolean => {
  const checkToken = getToken();
  if (checkToken === null) return false;
  return checkToken !== '';
};

const OAuthLink = new TokenRefreshLink({
  isTokenValidOrUndefined: isValidClientToken,
  fetchAccessToken: () => {
    return loginClient();
  },
  handleResponse: () => (response: { error?: any; token_type?: any; access_token?: any; expires_in?: any }) => {
    if (response?.error) {
      return false;
    }
    const { token_type: type, access_token: token } = response;
    if (token) {
      const tokenRefreshed = `${type} ${token}`;
      setToken(tokenRefreshed);
      setRefreshToken('');
      return true;
    }
    setToken('');
    setRefreshToken('');
    return false;
  },
  handleError: () => {
    setToken('');
    setRefreshToken('');
  },
});

export default OAuthLink;
