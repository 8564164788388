import styled, { css } from 'styled-components';

import Skeleton from 'react-loading-skeleton';

import { ViewModeQueries } from '@helpers/viewModeHelper';
import { FieldError, StyledLabel } from '@components/FormElements/styled';

const fieldHeight_md = '48px';
const fieldHeight = '40px';
const borderWidth = '1px';

const AddonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: ${(props) => props.theme.spaces.s4};
  min-height: calc(${fieldHeight} - ${borderWidth} * 2);
  max-height: calc(${fieldHeight} - ${borderWidth} * 2);

  ${ViewModeQueries.MD} {
    min-height: calc(${fieldHeight_md} - ${borderWidth} * 2);
    max-height: calc(${fieldHeight_md} - ${borderWidth} * 2);
  }
`;
const AddonLeftWrap = styled(AddonWrap)`
  padding-left: ${(props) => props.theme.spaces.s16};
`;
const AddonRightWrap = styled(AddonWrap)`
  padding-right: ${(props) => props.theme.spaces.s16};
`;

const inputStyles = css`
  background: transparent;
  border: none;
  outline: none;
  align-self: normal;
  padding: ${(props) => `0 ${props.theme.spaces.s16}`};
  width: 100%;
  font-size: ${(props) => props.theme.fonts.body2.fontSize};
  line-height: ${(props) => props.theme.fonts.body2.lineHeight};
  color: ${(props) => props.theme.colors.gray1};
  font-weight: 400;

  ::placeholder {
    font: inherit;
    color: ${(props) => props.theme.colors.gray3};
  }

  ${ViewModeQueries.MD} {
    font-size: ${(props) => props.theme.fonts.body2.fontSize_md};
    line-height: ${(props) => props.theme.fonts.body2.lineHeight_md};
  }
`;

const FieldSkeleton = styled(Skeleton)`
  height: ${fieldHeight};
  min-height: ${fieldHeight};

  ${ViewModeQueries.MD} {
    height: ${fieldHeight_md};
    min-height: ${fieldHeight_md};
  }
`;

const wrapStyles = css`
  display: flex;
  align-items: flex-start;
  height: ${fieldHeight};
  min-height: ${fieldHeight};
  border: ${borderWidth} solid ${(props) => props.theme.colors.gray5};
  border-radius: ${(props) => props.theme.borderRadius.r4};
  background-color: ${(props) => props.theme.colors.white};
  transition: border 0.2s linear, box-shadow 0.2s linear;

  ${ViewModeQueries.MD} {
    height: ${fieldHeight_md};
    min-height: ${fieldHeight_md};
  }
`;

const InputWrap = styled.div`
  ${wrapStyles};
`;

const TextareaWrap = styled.div<{ rows?: number }>`
  ${wrapStyles};

  overflow: auto;
  min-width: 200px;
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  resize: vertical;

  ${({ rows = 1, theme }) => css`
    min-height: calc(${theme.fonts.body2.lineHeight} * ${rows} + 24px);

    ${ViewModeQueries.MD} {
      min-height: calc(${theme.fonts.body2.lineHeight_md} * ${rows} + 24px);
    }
  `};
`;

export interface StyledFieldProps {
  isFocused?: boolean;
  hasError?: boolean;
  hasValue?: boolean;
  isDisabled?: boolean;
}
const StyledField = styled.div<StyledFieldProps>`
  width: 100%;

  .field-input-element {
    ${inputStyles};
  }
  textarea.field-input-element {
    resize: none;
    border: none;
    outline: none;
    overflow-y: auto;
    padding-top: ${(props) => props.theme.spaces.s12};
    padding-bottom: ${(props) => props.theme.spaces.s12};
  }

  ${(props) =>
    props.hasValue &&
    css`
      ${InputWrap},
      ${TextareaWrap} {
        border-color: ${props.theme.colors.primary_lighter};
      }
    `};

  ${(props) =>
    props.isFocused &&
    css`
      ${InputWrap},
      ${TextareaWrap} {
        border-color: ${props.theme.colors.primary_lighter};
        box-shadow: 0 0 0 1px ${props.theme.colors.primary_lighter};
      }
    `};

  ${(props) =>
    props.hasError &&
    css`
      ${InputWrap},
      ${TextareaWrap} {
        border-color: ${props.theme.colors.danger};
        box-shadow: ${props.isFocused ? `0 0 0 1px ${props.theme.colors.danger}` : 'none'};
      }

      ${StyledLabel},
      .field-input-element,
      .field-input-element::placeholder {
        color: ${props.theme.colors.danger};
      }
    `};

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;

      ${InputWrap},
      ${TextareaWrap} {
        pointer-events: none;
        border-color: ${props.theme.colors.input.bg_disabled};
        box-shadow: none;
      }

      ${StyledLabel},
      ${FieldError},
      .field-input-element,
      .field-input-element::placeholder {
        color: ${props.theme.colors.gray4};
      }
      .field-input-element {
        background-color: ${props.theme.colors.input.bg_disabled};
      }
    `};
`;

export { StyledField, InputWrap, TextareaWrap, AddonLeftWrap, AddonRightWrap, wrapStyles, inputStyles, FieldSkeleton };
