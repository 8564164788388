import { gql } from '@apollo/client';

export const PROMOTIONS_SUMMARY = gql`
  query promotionsSummary($userId: ID!) {
    promotionsSummary(userId: $userId) {
      promotionsAvailable
      ordersRequested
      ordersExpiredAndRedeemed
    }
  }
`;

export const USER_POINTS = gql`
  query UserPoints($source: String!, $userId: ID!) {
    UserPoints(source: $source, userId: $userId) {
      amount
    }
  }
`;
