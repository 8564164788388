import { LinkProps } from 'react-router-dom';

import { StyledActionNavLink } from './styled';
import useActionButton, { ActionButtonProps } from './useActionButton';

const ActionNavLink = (props: ActionButtonProps<LinkProps>) => {
  const { isDisabled, onClick, ...modifiedProps } = useActionButton<LinkProps>(props);

  return <StyledActionNavLink isDisabled={isDisabled} {...modifiedProps} />;
};

export default ActionNavLink;
