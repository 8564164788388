import { ActionType, ActionTypes, State } from './types';

const initialState = {
  isLoading: false,
  error: '',
  items: [],
};

const historyReducer = (state: State = initialState, action: ActionType): State => {
  switch (action.type) {
    case ActionTypes.FETCH_HISTORY_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    case ActionTypes.FETCH_HISTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default historyReducer;
