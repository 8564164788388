const PROD: string = 'production';
const DEV: string = 'develop';
const PRE: string = 'prepro';

const isPre = () => {
  const INDEX_OF_VALUE = -1;
  const HOST = 'prepro-public.shopadvizor.net';
  return window.location.host.indexOf(HOST) !== INDEX_OF_VALUE;
};

const isProd = () => {
  const INDEX_OF_VALUE = -1;
  const PRODUCTION_HOST = 'public.shopadvizor.com';

  return window.location.host.indexOf(PRODUCTION_HOST) !== INDEX_OF_VALUE;
};

const getEnvironment = () => {
  if (isProd()) return PROD;
  if (isPre()) return PRE;
  return DEV;
};

const getOAuthUrl = () => {
  const currentEnv = getEnvironment();
  switch (currentEnv) {
    case PROD:
      return process.env.REACT_APP_OAUTH_URL_PROD;
    case PRE:
      return process.env.REACT_APP_OAUTH_URL_PRE;
    default:
      return process.env.REACT_APP_OAUTH_URL;
  }
};
const getRawOAuthUrl = () => {
  const currentEnv = getEnvironment();
  switch (currentEnv) {
    case PROD:
      return process.env.REACT_APP_RAW_OAUTH_URL_PROD;
    case PRE:
      return process.env.REACT_APP_RAW_OAUTH_URL_PRE;
    default:
      return process.env.REACT_APP_RAW_OAUTH_URL;
  }
};

const getGwUrl = () => {
  const currentEnv = getEnvironment();
  switch (currentEnv) {
    case PROD:
      return process.env.REACT_APP_API_URL_PROD;
    case PRE:
      return process.env.REACT_APP_API_URL_PRE;
    default:
      return process.env.REACT_APP_API_URL;
  }
};

const getSpaUrl = () => {
  const currentEnv = getEnvironment();

  switch (currentEnv) {
    case PROD:
      return process.env.REACT_APP_SPA_URL_PROD;
    case PRE:
      return process.env.REACT_APP_SPA_URL_PRE;
    default:
      return process.env.REACT_APP_SPA_URL;
  }
};

const getLegacySurveyUrl = () => {
  const currentEnv = getEnvironment();
  switch (currentEnv) {
    case PROD:
      return process.env.REACT_APP_URL_LEGACY_SURVEY_SERVICE_PROD;
    case PRE:
      return process.env.REACT_APP_URL_LEGACY_SURVEY_SERVICE_PRE;
    case DEV:
      return process.env.REACT_APP_URL_LEGACY_SURVEY_SERVICE_DEVELOP;
    default:
      return process.env.REACT_APP_URL_LEGACY_SURVEY_SERVICE_PRE;
  }
};

const getClientSecret = () => {
  const currentEnv = getEnvironment();
  switch (currentEnv) {
    case PROD:
    case PRE:
      return process.env.REACT_APP_CLIENT_SECRET_PROD;
    default:
      return process.env.REACT_APP_CLIENT_SECRET;
  }
};

export { getOAuthUrl, getGwUrl, getLegacySurveyUrl, getClientSecret, getRawOAuthUrl, getSpaUrl };
