const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh';
const EXPIRATION_KEY = 'expires';

const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);
const getRefresh = (): string | null => localStorage.getItem(REFRESH_TOKEN_KEY);
const getExpirationDate = (): string | null => localStorage.getItem(EXPIRATION_KEY);

const setToken = (token: string): void => localStorage.setItem(TOKEN_KEY, token);
const setRefreshToken = (token: string): void => localStorage.setItem(REFRESH_TOKEN_KEY, token);
const setExpirationDate = (timestamp: string): void => localStorage.setItem(EXPIRATION_KEY, timestamp);

export { getToken, getRefresh, getExpirationDate, setToken, setRefreshToken, setExpirationDate };
