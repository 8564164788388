import styled, { css, keyframes } from 'styled-components';

import { NotificationType } from '@store/notifications/types';
import { ViewModeQueries } from '@helpers/viewModeHelper';

const fadeInAppearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOutAppearAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const slideUpAppearAnimation = keyframes`
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
`;
const slideDownAppearAnimation = keyframes`
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
`;

const ActionButton = styled.button`
  display: inline-block;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fonts.body2.fontSize_md};
  line-height: ${(props) => props.theme.fonts.body2.lineHeight_md};
  font-weight: 600;
`;

const IconWrap = styled.span`
  display: inline-flex;
  margin-right: ${(props) => props.theme.spaces.s20};
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.body2.fontSize_md};
  line-height: ${(props) => props.theme.fonts.body2.lineHeight_md};
  margin-right: ${(props) => props.theme.spaces.s20};

  ${ViewModeQueries.MD} {
    font-size: ${(props) => props.theme.fonts.body1.fontSize_md};
    line-height: ${(props) => props.theme.fonts.body1.lineHeight_md};
  }
`;

const TopHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrap = styled.div`
  width: 300px;
  display: grid;
  row-gap: ${(props) => props.theme.spaces.s16};
  position: fixed;
  z-index: 100;
  top: 52px;
  left: 50%;
  transform: translate(-50%);

  ${ViewModeQueries.SM} {
    width: 462px;
  }

  ${ViewModeQueries.MD} {
    top: 76px;
  }

  ${ViewModeQueries.LG} {
    top: 88px;
  }

  ${ViewModeQueries.XL} {
    width: 768px;
  }
`;

interface NotificationProps {
  isVisible?: boolean;
  type?: NotificationType;
  animationDuration?: number;
}

const NotificationWrap = styled.div<NotificationProps>`
  border-radius: ${(props) => props.theme.borderRadius.r4};
  color: ${(props) => props.theme.colors.notifications.color};
  flex-basis: 100%;
  position: relative;
  padding: ${(props) => `${props.theme.spaces.s12} ${props.theme.spaces.s16}`};
  background-color: ${(props) => props.theme.colors.notifications.bg};
  box-shadow: 2px 2px 4px rgb(0 0 0 / 5%);

  animation: ${(props) => css`
    ${fadeInAppearAnimation} ${props.animationDuration}ms ease-in-out forwards,
    ${slideDownAppearAnimation} ${props.animationDuration}ms ease-in-out forwards
  `};

  ${(props) =>
    !props.isVisible &&
    css`
      animation: ${css`
        ${fadeOutAppearAnimation} ${props.animationDuration}ms ease-in-out forwards,
        ${slideUpAppearAnimation} ${props.animationDuration}ms ease-in-out forwards
      `};
    `};

  a {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export { NotificationWrap, Wrap, TopHead, IconWrap, ActionButton, Title };
