import { RootState } from '@store';

const userSelector = (state: RootState) => state.user;
const userDataSelector = (state: RootState) => state.user.data;
const userSummarySelector = (state: RootState) => state.user.summary;
const userCreditsSelector = (state: RootState) => state.user.credits;
const userIsLoadingSelector = (state: RootState) => state.user.isLoading;
const userErrorSelector = (state: RootState) => state.user.error;

export {
  userSelector,
  userDataSelector,
  userSummarySelector,
  userIsLoadingSelector,
  userErrorSelector,
  userCreditsSelector,
};
