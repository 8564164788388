import { gql } from '@apollo/client';

const ME = gql`
  query me {
    me {
      name
      lastName
      username
      imageUrl
      id
      telephone
      telephoneVerifiedAt
      profile {
        id
        gender
        birthDate
        address {
          line1
          line2
          city
          postalCode
          state
        }
        partner {
          firstName
          birthDate
          gender
        }
        educationLevel {
          code
        }
        children {
          firstName
          birthDate
        }
        numberOfHomeComponents
        pets {
          type
        }
        policies {
          code
          name
        }
        preference {
          alcohol
          bio
          light
          noGluten
        }
        notAnswered
      }
    }
  }
`;

export default ME;
