import { getOAuthUrl, getClientSecret } from '@config/getEnviroment';

const urlOAuth = getOAuthUrl();
const clientSecret = getClientSecret();

const loginUser: ({ username, password }: { username: string; password: string }) => Promise<any | void> = async ({
  username,
  password,
}) =>
  fetch(urlOAuth as any, {
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      grant_type: process.env.REACT_APP_PASSWORD_GRANT_TYPE,
      client_id: process.env.REACT_APP_CREDENTIALS_CLIENT_ID,
      client_secret: clientSecret,
      scope: process.env.REACT_APP_SCOPE,
      username,
      password,
    } as any),
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      throw error;
    });

export default loginUser;
