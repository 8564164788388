import React, { useRef, useEffect, useCallback } from 'react';

import { Fill, Wrap } from '../styled';

import { PopUp } from './styled';

export interface ModalProps {
  isOpen: boolean;
  onClose: Function;
}

const Overlay: React.FunctionComponent<ModalProps> = ({ children, isOpen, onClose }) => {
  const fillRef = useRef(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (fillRef.current === event.target) {
        onClose();
      }
    },
    [onClose]
  );

  const handleEscape = useCallback(
    (event: any) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const release = () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleEscape);
      document.body.style.overflow = 'unset';
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keyup', handleEscape);
      document.body.style.overflow = 'hidden';
    } else {
      release();
    }
    return release;
  }, [isOpen, handleClickOutside, handleEscape]);

  return isOpen ? (
    <Wrap className="menu-modal">
      <Fill ref={fillRef} className="menu-modal__fill" />
      <PopUp className="menu-modal__popup">{children}</PopUp>
    </Wrap>
  ) : null;
};

export default Overlay;
