import styled, { css } from 'styled-components';

import { ViewModeQueries } from '@helpers/viewModeHelper';

interface ColorMixinProps {
  type?: 'default' | 'primary' | 'secondary' | 'note' | 'disabled' | 'error';
}

const colorMixin = css<ColorMixinProps>`
  color: ${(props) => {
    switch (props.type) {
      case 'primary':
        return props.theme.colors.text.primary;
      case 'secondary':
        return props.theme.colors.text.secondary;
      case 'note':
        return props.theme.colors.text.note;
      case 'disabled':
        return props.theme.colors.text.disabled;
      case 'error':
        return props.theme.colors.danger;
      default:
        return 'inherit';
    }
  }};
`;

const H1 = styled.h1`
  ${colorMixin};

  font-weight: ${(props) => props.theme.fonts.h1.fontWeight};
  font-size: ${(props) => props.theme.fonts.h1.fontSize};
  line-height: ${(props) => props.theme.fonts.h1.lineHeight};

  ${ViewModeQueries.MD} {
    font-size: ${(props) => props.theme.fonts.h1.fontSize_md};
    line-height: ${(props) => props.theme.fonts.h1.lineHeight_md};
  }
`;
const H2 = styled.h2`
  ${colorMixin};

  font-weight: ${(props) => props.theme.fonts.h2.fontWeight};
  font-size: ${(props) => props.theme.fonts.h2.fontSize};
  line-height: ${(props) => props.theme.fonts.h2.lineHeight};

  ${ViewModeQueries.MD} {
    font-size: ${(props) => props.theme.fonts.h2.fontSize_md};
    line-height: ${(props) => props.theme.fonts.h2.lineHeight_md};
  }
`;
const H3 = styled.h3`
  ${colorMixin};

  font-weight: ${(props) => props.theme.fonts.h3.fontWeight};
  font-size: ${(props) => props.theme.fonts.h3.fontSize};
  line-height: ${(props) => props.theme.fonts.h3.lineHeight};

  ${ViewModeQueries.MD} {
    font-size: ${(props) => props.theme.fonts.h3.fontSize_md};
    line-height: ${(props) => props.theme.fonts.h3.lineHeight_md};
  }
`;
const H4 = styled.h4`
  ${colorMixin};

  font-weight: ${(props) => props.theme.fonts.h4.fontWeight};
  font-size: ${(props) => props.theme.fonts.h4.fontSize};
  line-height: ${(props) => props.theme.fonts.h4.lineHeight};

  ${ViewModeQueries.MD} {
    font-size: ${(props) => props.theme.fonts.h4.fontSize_md};
    line-height: ${(props) => props.theme.fonts.h4.lineHeight_md};
  }
`;

const Subtitle = styled.p<{ size?: 'small' | 'default' } & ColorMixinProps>`
  ${colorMixin};

  ${(props) =>
    props.size === 'small'
      ? css`
          font-weight: ${props.theme.fonts.subtitle2.fontWeight};
          font-size: ${props.theme.fonts.subtitle2.fontSize};
          line-height: ${props.theme.fonts.subtitle2.lineHeight};
        `
      : css`
          font-weight: ${props.theme.fonts.subtitle1.fontWeight};
          font-size: ${props.theme.fonts.subtitle1.fontSize};
          line-height: ${props.theme.fonts.subtitle1.lineHeight};

          ${ViewModeQueries.MD} {
            font-size: ${props.theme.fonts.subtitle1.fontSize_md};
            line-height: ${props.theme.fonts.subtitle1.lineHeight_md};
          }
        `};
`;

const textStyles = css<
  {
    size?: 'small' | 'default' | 'large';
    isBold?: boolean;
  } & ColorMixinProps
>`
  ${colorMixin};

  font-weight: ${(props) => (props.isBold ? '600' : props.theme.fonts.primary.fontWeight)};

  ${(props) => {
    switch (props.size) {
      case 'small':
        return css`
          font-size: ${props.theme.fonts.body3.fontSize};
          line-height: ${props.theme.fonts.body3.lineHeight};

          ${ViewModeQueries.MD} {
            font-size: ${props.theme.fonts.body3.fontSize_md};
            line-height: ${props.theme.fonts.body3.lineHeight_md};
          }
        `;
      case 'large':
        return css`
          font-size: ${props.theme.fonts.body1.fontSize};
          line-height: ${props.theme.fonts.body1.lineHeight};

          ${ViewModeQueries.MD} {
            font-size: ${props.theme.fonts.body1.fontSize_md};
            line-height: ${props.theme.fonts.body1.lineHeight_md};
          }
        `;
      default:
        return css`
          font-size: ${props.theme.fonts.body2.fontSize};
          line-height: ${props.theme.fonts.body2.lineHeight};

          ${ViewModeQueries.MD} {
            font-size: ${props.theme.fonts.body2.fontSize_md};
            line-height: ${props.theme.fonts.body2.lineHeight_md};
          }
        `;
    }
  }};
`;

const Text = styled.span`
  ${textStyles};
`;

const Paragraph = styled.p`
  ${textStyles};
`;

export { H1, H2, H3, H4, Subtitle, Text, Paragraph };
