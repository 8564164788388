import {
  ActionTypes,
  FetchUserFailAction,
  FetchUserStartAction,
  FetchUserSuccessAction,
  cleanUserAction,
  FetchUserSummaryAction,
  FetchUserCreditsAction,
} from './types';

import { User, UserSummary } from '@/types/user';

const fetchUserSuccess = (payload: User): FetchUserSuccessAction => ({
  type: ActionTypes.FETCH_USER_SUCCESS,
  payload,
});
const cleanUser = (): cleanUserAction => ({
  type: ActionTypes.CLEAN_USER,
});
const fetchUserStart = (): FetchUserStartAction => ({
  type: ActionTypes.FETCH_USER_START,
});

const fetchUserFail = (payload: string): FetchUserFailAction => ({
  type: ActionTypes.FETCH_USER_FAIL,
  payload,
});

const fetchUserSummary = (payload: UserSummary): FetchUserSummaryAction => ({
  type: ActionTypes.FETCH_USER_SUMMARY,
  payload,
});

const fetchUserCredits = (payload: number): FetchUserCreditsAction => ({
  type: ActionTypes.FETCH_USER_CREDITS,
  payload,
});

export { fetchUserSuccess, fetchUserStart, fetchUserFail, cleanUser, fetchUserSummary, fetchUserCredits };
