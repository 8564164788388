const EnglishTranslation = {
  translation: {
    common: {
      OR: 'or',
      upToDate: 'You are up to date',
      visitSite: 'Go to the site',
      assessment: {
        excellent: 'Excellent',
        veryGood: 'Very good',
        good: 'Good',
        acceptable: 'Acceptable',
        bad: 'Bad',
      },
      back: 'Back',
      cancel: 'Cancel',
      send: 'Send',
    },
    validation: {
      error: {
        invalidEmail: 'Invalid email format',
        required: 'This field is required',
        passwordMismatch: 'Password does not match',
      },
    },
    option: {
      gender: {
        choose: 'Choose one',
        MALE: 'Man',
        FEMALE: 'Woman',
        NOT_ANSWERED: '-',
      },
      policies: {
        platformCommunications: 'Platform Communications',
        retailerCommunications: 'Retailer Communications',
        dataLinkWithSAZ: 'Link profile with ShopAdvizor',
        thirdPartyCommunications: 'Third Party Communications',
      },
      educationLevel: {
        0: 'Level 0: Pre-primary education',
        1: 'Level 1: Primary education or first stage of basic education',
        2: 'Level 2: Lower secondary education or second stage of basic education',
        3: 'Level 3: Upper secondary education',
        4: 'Level 4: Post-secondary non-tertiary education',
        5: 'Level 5: Short-cycle tertiary education',
        6: "Level 6: Bachelor's or equivalent",
        7: "Level 7: Master's or equivalent",
        8: 'Level 8: Doctoral or equivalent',
      },
      yesOrNot: {
        YES: 'Yes',
        NO: 'No',
        NOT_ANSWERED: '-',
      },
      preference: {
        alcohol: 'Alcohol',
        bio: 'Bio',
        light: 'Light',
        noGluten: 'Without gluten',
      },
      numberOfHomeComponent: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        '5+': 'More than 5',
      },
    },
    forms: {
      signIn: {
        caption: 'Log in',
        label: {
          username: 'Phone number',
          password: 'Password',
        },
        placeholder: {
          username: 'Your phone number',
          password: 'Your password',
        },
        cta: {
          signUp: 'Create your account',
          resetPass: 'Forgot password?',
          monavis: 'Connect through Monavislerendgratuit.com',
          submit: 'Log in',
        },
        error: {
          invalid: 'Unable to login, check credentials',
          network: 'Network error',
        },
      },
      signUp: {
        caption: 'Sign Up',
        label: {
          email: 'Email',
          emailRepeat: 'Email Repeat',
          password: 'Password',
          passwordRepeat: 'Password Repeat',
          firstName: 'First Name',
          lastName: 'Last Name',
          gender: 'Gender',
          birthDate: 'Date of birth',
          phoneNumber: 'Phone number',
          mailAgreement: 'Yes, i want to receive in my email promotions, offers and other info from shopadvizor.com ',
          policyAgreement: 'Yes, i accept conditions.',
        },
        placeholder: {
          email: 'Your email',
          emailRepeat: 'Repeat email',
          password: 'Your password',
          passwordRepeat: 'Repeat password',
          firstName: 'Your firstname',
          lastName: 'Your lastname',
          gender: 'Your gender',
          birthDate: 'Your birthdate',
          phoneNumber: 'Your phone number',
        },
        cta: {
          logIn: 'Already registered?, Log in',
          submit: 'Create your account',
        },
        error: {
          network: 'Network error',
        },
      },
      profile: {
        headers: {
          policies: 'Policies',
          preferences: 'Preferences',
          education: 'Education',
          couple: 'Couple',
          pet: 'Pet',
        },
        label: {
          gender: 'Gender',
          partnerGender: 'Gender',
          partnerFirstName: 'First Name',
          partnerBirthDate: 'Birth date',
          numberOfHomeComponents: 'Number of home components',
          state: 'State',
          city: 'City',
          zipCode: 'Zip code',
          line1: 'Address',
          line2: 'Address complementary',
          hasPartner: 'hasPartner',
          hasPet: 'hasPartner',
          educationLevelCode: '',
          birthDate: 'Date of birth',
        },
        placeholder: {
          gender: 'Gender',
          partnerGender: 'Your partner gender',
          partnerFirstName: 'Your partner firstname',
          partnerBirthDate: 'Your partner lastname',
          numberOfHomeComponents: 'Number of people who you live with',
          state: 'State',
          city: 'City',
          zipCode: 'Zip code',
          line1: 'Enter your address',
          line2: 'Enter your additional info',
          hasPartner: 'Do you have a partner?',
          hasPet: 'Do you have a pet?',
          educationLevelCode: 'What are your educational level?',
          birthDate: 'Date of birth',
        },
        cta: {
          logIn: 'Already registered?, Log in',
          submit: 'Create your account',
        },
        error: {
          network: 'Network error',
        },
      },
      photo: {
        description: {
          title: 'Change your avatar image',
          subtitle1: 'It must be jpg,png',
          subtitle2: 'it must be below 2.5M',
        },
        cta: {
          add: 'Add photo',
          edit: 'Edit photo',
          delete: 'Delete photo',
        },
        caption: {
          file: 'Change your avatar',
          submit: 'Save changes',
        },
        notification: {
          success: 'Your photo has changed',
          error: 'Error, connection failed',
        },
      },
      resetPassword: {
        caption: 'Forgot your password?',
        captionError: 'Not valid token.',
        label: {
          email: 'Email',
        },
        placeholder: {
          email: 'Your email',
        },
        notification: {
          success: 'We’ve sent an email with a link to reset your password.',
          error: 'Error, connection failed',
        },
        cta: {
          submit: 'Send reset-password link',
          back: 'Back to login',
        },
      },
      confirmPassword: {
        caption: 'Forgot your password?',
        captionError: 'Not valid token.',
        label: {
          password: 'Password',
          confirm: 'Confirm password',
        },
        placeholder: {
          password: 'Enter your new password',
          confirm: 'Confirm your new password',
        },
        notification: {
          success: 'Your password has changed',
          error: 'Error, connection failed',
        },
        cta: {
          submit: 'Send',
        },
      },
      commentCreate: {
        cta: {
          leaveComment: 'Create a review',
        },
        error: {
          noRate: 'You must rate the product',
          commentBelowMinimum: 'Minimal comment length 10 characters.',
        },
      },
    },
    pages: {
      confirmEmail: {
        caption: 'Confirm your email',
        success: {
          title: 'Your email has been confirmed!',
          text: '',
        },
        error: {
          title: 'Your email has not been confirmed!',
        },
      },
      productDetail: {
        caption: {
          ctaToolTipStarsBefore: 'Hey, hey,',
          ctaToolTipStarsAfter: 'you can touch the stars!',
          ingredients: 'Ingredients',
          allergens: 'Allergens',
          description: 'Description',
          nutrients: 'Nutrients',
          moreInfo: 'More information',
          ratingDetails: 'Rating details',
          productRating: 'Current product rating',
          userReviews: 'User reviews',
          nutriscore: 'About nutriscore',
          range: 'Nutrients range',
          totalReviews: '(Total reviews)',
        },
        texts: {
          ratingDetails:
            'Basically, the nutritional value for the our grade is determined by subtracting the total ' +
            'positive points from the total negative points. And the lower the score, the better the letter grade.',
          nutriscore:
            'Voici comment est attribué le Nutri-Score, un algorithme attribue des points à chaque élément repris dans le ' +
            'tableau des valeurs nutritionnelles (par 100 g ou 100 ml), tant pour les substances alimentaires bénéfiques ' +
            'affichant un score négatif (protéines, fibres, quantité de fruits, de légumes, de fruits à coque, d&apos;huile ' +
            'de colza, d&apos;huile de noix et d&apos;huile d&apos;olive) que pour les substances alimentaires moins ' +
            'bénéfiques affichant un score positif (haute valeur énergétique [kJ], sucres, acides gras saturés, sel).' +
            'Ensuite, les points positifs (moins bénéfiques, donc) sont déduits des points négatifs (bénéfiques) et le' +
            'résultat est converti dans le tableau du Nutri-Score. ',
        },
      },
    },
  },
};

export default EnglishTranslation;
