import { LinkProps } from 'react-router-dom';

import { StyledNavLink } from './styled';
import { ButtonProps } from './types';
import useButton from './useButton';

const NavLink = (props: ButtonProps<LinkProps>) => {
  const modifiedProps = useButton<LinkProps>(props);

  return <StyledNavLink {...modifiedProps} />;
};

export default NavLink;
