import styled, { DefaultTheme, FlattenInterpolation, keyframes, ThemeProps } from 'styled-components';

import CrossIcon from '@assets/icons/cross.svg';
import { ViewModeQueries } from '@helpers/viewModeHelper';

const animationDuration = 200;

const appearance = keyframes`
  0% {
    opacity: 0;
    backdrop-filter: blur(0);
  }
  100% {
    opacity: 1;
    backdrop-filter: blur(2px);
  }
`;

const CloseButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  background-image: url('${CrossIcon}');
  background-size: cover;
`;

const Fill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45.26deg, rgba(86, 204, 242, 0.228) 11.7%, rgba(255, 255, 255, 0) 104.9%),
    linear-gradient(
      248.66deg,
      rgba(251, 133, 0, 0.3) -3.46%,
      rgba(244, 225, 229, 0.3) -3.45%,
      rgba(233, 212, 216, 0.228) 95.08%
    ),
    rgba(255, 255, 255, 0.6);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), inset -1px -0.5px 2px rgba(255, 255, 255, 0.4);
  animation: ${appearance} ${animationDuration}ms forwards;
`;

const Caption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spaces.s16};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray6};
`;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spaces.s16};
`;

const PopUp = styled.div<{ isContentWidth?: boolean }>`
  overflow-y: auto;
  position: relative;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.white};
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 24px rgba(18, 69, 131, 0.16);

  ${ViewModeQueries.MD} {
    min-height: 100px;
    height: auto;
    border-radius: ${(props) => props.theme.borderRadius.r4};
    max-height: 70%;
    width: ${(props) => (props.isContentWidth ? 'auto' : '450px;')};
  }
`;

const SubmitFooter = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  margin-top: auto;
  padding-top: ${(props) => props.theme.spaces.s28};
  column-gap: ${(props) => props.theme.spaces.s8};
`;

const Wrap = styled.div<{ styledCss?: FlattenInterpolation<ThemeProps<DefaultTheme>> }>`
  width: 100%;
  height: 100%;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${(props) => (props.styledCss ? props.styledCss : '')};
`;

export { Wrap, Fill, PopUp, Caption, Container, SubmitFooter, CloseButtonRow, CloseButton };
