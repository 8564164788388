import styled, { css } from 'styled-components';

import CheckboxIcon from '@assets/icons/checkbox-vector.svg';

const FormFieldError = styled.p`
  position: absolute;
  top: calc(100% + 4px);
  left: 20px;
  font-size: ${(props) => props.theme.fonts.body3.fontSize};
  line-height: ${(props) => props.theme.fonts.body3.lineHeight};
  color: ${(props) => props.theme.colors.danger};
`;

interface FormFieldWrapProps {
  isError?: boolean;
  hasLabel?: boolean;
  disabled?: boolean;
}

const FormFieldWrap = styled.label<FormFieldWrapProps>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};
`;

const CheckboxWrap = styled(FormFieldWrap)<{ isDisabled?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: start;

  ${(props) => props.isDisabled && 'opacity: 0.5'};
`;

const CheckboxInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  border: none;
  position: absolute;
  z-index: -1;
`;

const Checkbox = styled.div<{ isChecked?: boolean; isDisabled?: boolean }>`
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.primary_lightest};
  box-sizing: border-box;
  opacity: 0.5;
  transition: box-shadow 0.2s linear;

  ${(props) =>
    props.isDisabled &&
    css`
      border-color: ${props.theme.colors.gray4};
    `}

  ${(props) =>
    props.isChecked &&
    !props.isDisabled &&
    css`
      background-color: ${props.theme.colors.primary_lighter};
      border-color: ${props.theme.colors.primary_lighter};
      opacity: 1;
      display: inline-block;
      cursor: pointer;
      background-image: url(${CheckboxIcon});
      background-position: center;
      background-repeat: no-repeat;
    `}

  ${(props) =>
    props.isChecked &&
    props.isDisabled &&
    css`
      background-color: ${props.theme.colors.gray4};
      border-color: ${props.theme.colors.gray4};
      opacity: 1;
      display: inline-block;
      cursor: pointer;
      background-image: url(${CheckboxIcon});
      background-position: center;
      background-repeat: no-repeat;
    `}

  ${(props) =>
    !props.isChecked &&
    !props.isDisabled &&
    css`
      &:hover {
        box-shadow: inset 0 0 0 1px ${props.theme.colors.primary_lightest};
      }
    `}
`;

const CheckboxLabel = styled.label<{ labelSide?: 'left' | 'right' }>`
  cursor: pointer;
  ${({ labelSide = 'right', theme }) => `margin-${labelSide === 'left' ? 'right' : 'left'}: ${theme.spaces.s12}`};
  font-size: ${(props) => props.theme.fonts.body3.fontSize_md};
  line-height: ${(props) => props.theme.fonts.body3.lineHeight_md};

  ${(props) =>
    props.labelSide === 'left' &&
    css`
      order: -1;
    `}
`;

export { FormFieldError, CheckboxInput, CheckboxWrap, Checkbox, CheckboxLabel };
