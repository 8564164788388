import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ViewModeQueries } from '@helpers/viewModeHelper';

export interface StyledActionButtonProps {
  isBlock?: boolean;
  isDisabled?: boolean;
  size?: 'default' | 'small';
  color?: string;
}

const actionButtonStyles = css<StyledActionButtonProps>`
  display: ${(props) => (props.isBlock ? 'flex' : 'inline-flex')};
  align-items: center;
  color: ${(props) => {
    return props.color && props.color?.length > 0 ? props.color : props.theme.colors.gray2;
  }}};
  text-decoration: none;

  ${(props) =>
    props.size === 'small' &&
    css`
      font-size: ${props.theme.fonts.body2.fontSize};
      line-height: ${props.theme.fonts.body2.lineHeight};
      height: ${props.theme.fonts.body2.lineHeight};

      ${ViewModeQueries.MD} {
        font-size: ${props.theme.fonts.body2.fontSize_md};
        line-height: ${props.theme.fonts.body2.lineHeight_md};
        height: ${props.theme.fonts.body2.lineHeight_md};
      }
    `};

  ${(props) =>
    (props.size === undefined || props.size === 'default') &&
    css`
      font-size: ${props.theme.fonts.body1.fontSize};
      line-height: ${props.theme.fonts.body1.lineHeight};
      height: ${props.theme.fonts.body1.lineHeight};

      ${ViewModeQueries.MD} {
        font-size: ${props.theme.fonts.body1.fontSize_md};
        line-height: ${props.theme.fonts.body1.lineHeight_md};
        height: ${props.theme.fonts.body1.lineHeight_md};
      }
    `};

  svg {
    margin-right: ${(props) => props.theme.spaces.s4};
    max-height: 100%;

    ${ViewModeQueries.MD} {
      margin-right: ${(props) => props.theme.spaces.s8};
    }
  }

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
      color: ${props.theme.colors.gray4};

      svg path {
        stroke: ${props.theme.colors.gray4};
      }
    `};

  ${ViewModeQueries.MD} {
    ${(props) =>
      props.size === 'small' &&
      css`
        font-size: ${props.theme.fonts.body2.fontSize_md};
        line-height: ${props.theme.fonts.body2.lineHeight_md};
        height: ${props.theme.fonts.body2.lineHeight_md};
      `};

    ${(props) =>
      props.size === 'default' &&
      css`
        font-size: ${props.theme.fonts.body1.fontSize_md};
        line-height: ${props.theme.fonts.body1.lineHeight_md};
        height: ${props.theme.fonts.body1.lineHeight_md};
      `};
    svg {
      margin-right: ${(props) => props.theme.spaces.s4};
    }
  }
`;

const StyledActionButton = styled.button<StyledActionButtonProps>`
  ${actionButtonStyles};
`;

const StyledActionNavLink = styled(NavLink)<StyledActionButtonProps>`
  ${actionButtonStyles};
`;

const StyledActionLink = styled.a<StyledActionButtonProps>`
  ${actionButtonStyles};
`;

export { StyledActionButton, StyledActionLink, StyledActionNavLink };
